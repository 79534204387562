import htmlTemplate from '../../fileTemplates/schedule/htmlTemplate'
import { FileNames } from '../../params/constants'
import { MailingEventType } from '../../params/mailing'
import { ScheduleType } from '../../params/schedule'
import { AdditionalDate, UlEvent } from '../../types/DTO/UlEvent.dto'
import { datesToReadableString } from '../Formatters/dateFormatters'

import saveFile from './saveFile'

interface Params {
  scheduleType: ScheduleType
  dates: string
}
interface EventParams { withUrl: boolean }

export default function renderHtmlScheduleDOCs (events: UlEvent[], params: Params): void {
  const { scheduleType, dates } = params

  const renderSingleEvent = (event: UlEvent, { withUrl }: EventParams): string => {
    const title = `<br>📍 ${event.eventType === 'webinar' ? `Вебинар "${event.title}"` : event.title}<br>`

    const eventDateTime: string = event.allDates !== undefined
    // если у меропрития несколько дат
      ? event.allDates.map((dateObj: AdditionalDate) => `${datesToReadableString(new Date(dateObj.dateStart), new Date(dateObj.dateEnd))} в ${dateObj.timeStart}${withUrl ? `<br>${dateObj.url}` : ''}<br>`).join('')
      // если одна дата
      : `${
        // пустые даты начала и конца === мероприятие добавили вручную, через ссылку
      event.dateStart === '' && event.dateEnd === ''
      ? event.formattedDate
      : datesToReadableString(new Date(event.dateStart), new Date(event.dateEnd))
      }${event.timeStart !== '' && event.dateStart === event.dateEnd ? ` в ${event.timeStart}` : ''}${withUrl ? `<br>${event.url}<br>` : '<br>'}`

    const lectorTitle: string = event.lectorTitle && event.lectorName ? event.lectorTitle.charAt(0).toUpperCase() + event.lectorTitle.slice(1) : 'Гид'

    return `<strong>${title}</strong><br>${eventDateTime} ${event.length !== '' ? event.length + ',' : ''} ${event.price} р.<br>${lectorTitle}: ${event.lectorName}<p>`
  }

  const renderEvents = ({ withUrl }: EventParams): string => {
    return events.length > 0
      ? `<p><p>${events.map((event) => event.eventType !== MailingEventType.COURSE ? renderSingleEvent(event, { withUrl }) : '').join('')}`
      : ''
  }

  const renderMonthlyEvents = ({ withUrl }: EventParams): string => {
    let resultString: string = ''

    const excursions = events.filter(event => event.eventType === MailingEventType.EXCURSION)
    if (excursions.length > 0) { resultString += `<br><strong style="text-decoration: underline">ЭКСКУРСИИ</strong><br><br>${excursions.map(excursion => renderSingleEvent(excursion, { withUrl })).join('')}` }
    const spbEvents = events.filter(event => event.eventType === MailingEventType.SPB)
    if (spbEvents.length > 0) { resultString += `<br><strong style="text-decoration: underline">УНИКАЛЬНЫЕ АВТОРСКИЕ ПРОГРАММЫ ПУТЕШЕСТВИЙ В ПЕТЕРБУРГ</strong><br><br>${spbEvents.map(spbEvent => renderSingleEvent(spbEvent, { withUrl })).join('')}` }
    const eventsAndLectures = events.filter(event => event.eventType === MailingEventType.EVENT || event.eventType === MailingEventType.LECTURE)
    if (eventsAndLectures.length > 0) { resultString += `<br><strong style="text-decoration: underline">МЕРОПРИЯТИЯ И ЖИВЫЕ ЛЕКЦИИ</strong><br><br>${eventsAndLectures.map(event => renderSingleEvent(event, { withUrl })).join('')}` }
    const webinars = events.filter(event => event.eventType === MailingEventType.WEBINAR)
    if (webinars.length > 0) {
      resultString += `<br><strong style="text-decoration: underline">ВЕБИНАРЫ</strong><br><br>
<i>Все вебинары можно смотреть как в прямом эфире, так и в записи. По окончании лекции на Вашу электронную почту придет ссылка на запись вебинара. Запись будет доступна для просмотра в течение одного месяца неограниченное количество раз.</i><br><br>
${webinars.map(webinar => renderSingleEvent(webinar, { withUrl })).join('')}`
    }
    const courses = events.filter(event => event.eventType === MailingEventType.COURSE)
    if (courses.length > 0) { resultString += `<br><strong style="text-decoration: underline">КУРСЫ</strong><br><br>${courses.map(course => renderSingleEvent(course, { withUrl })).join('')}` }

    return resultString
  }

  const resultObject = {
    [ScheduleType.WeekEnd]: () => ({
      htmlWithUrls: htmlTemplate(dates, scheduleType, renderEvents({ withUrl: true })),
      htmlWithoutUrls: htmlTemplate(dates, scheduleType, renderEvents({ withUrl: false }) + '<br>Приобрести билет можно на нашем сайте в разделе «Расписание» (сайт указан в шапке профиля).<p> Отвечаем на все вопросы в Директе, по имейл hello@umnyeludi.ru и по тел. 8 985 772 50 16')
    }),
    [ScheduleType.Weekly]: () => ({
      htmlWithUrls: htmlTemplate(dates, scheduleType, renderEvents({ withUrl: true })),
      htmlWithoutUrls: htmlTemplate(dates, scheduleType, renderEvents({ withUrl: false }) + '<br>Приобрести билет можно на нашем сайте в разделе «Расписание» (сайт указан в шапке профиля).<p> Отвечаем на все вопросы в Директе, по имейл hello@umnyeludi.ru и по тел. 8 985 772 50 16')
    }),
    [ScheduleType.Monthly]: () => ({
      htmlWithUrls: htmlTemplate(dates, scheduleType, renderMonthlyEvents({ withUrl: true })),
      htmlWithoutUrls: htmlTemplate(dates, scheduleType, renderMonthlyEvents({ withUrl: false }))
    })
  }[scheduleType]()

  const blobWithUrls = new Blob([resultObject.htmlWithUrls], { type: 'text/html' })
  const blobWithoutUrls = new Blob([resultObject.htmlWithoutUrls], { type: 'text/html' })

  saveFile(blobWithUrls, FileNames.scheduleUrls)
  saveFile(blobWithoutUrls, FileNames.scheduleNoUrls)
}
