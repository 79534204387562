export enum ScheduleType {
  Weekly = 'weekly',
  WeekEnd = 'weekEnd',
  Monthly = 'monthly'
}

  type ScheduleTypes = Record<ScheduleType, {
    name: string
    renderPhrase: string
  }>

export const scheduleTypes: ScheduleTypes = {
  [ScheduleType.Weekly]: { name: 'На неделю', renderPhrase: 'Представляем Вам наше расписание на неделю' },
  [ScheduleType.WeekEnd]: { name: 'На выходные', renderPhrase: 'Представляем Вам наше расписание на выходные' },
  [ScheduleType.Monthly]: { name: 'На месяц', renderPhrase: '' }
}
