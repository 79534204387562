import React, { useEffect } from 'react'
import RenderChapter from './RenderChapter'
import htmlHead from '../../../fileTemplates/mailing/htmlHead'
import htmlBodyTop from '../../..//fileTemplates/mailing/htmlBodyTop'
import htmlBodyBottom from '../../../fileTemplates/mailing/htmlBodyBottom'
import saveFile from '../../../utils/RenderFiles/saveFile'
import { UlEvent } from '../../../types/DTO/UlEvent.dto'
import { ScheduleType } from '../../../params/schedule'
import { FileNames } from '../../../params/constants'
import { chapters } from '../../../params/mailing'

interface Props {
  scheduleType: ScheduleType
  dates: string
  events: UlEvent[]
}

export default function RenderHtmlFile (props: Props): JSX.Element {
  const { dates, scheduleType, events } = props

  const divRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
  const buildHTML = (): void => {
    const blob = new Blob([`${htmlHead}${htmlBodyTop(dates, scheduleType)}${divRef.current.innerHTML}${htmlBodyBottom}`], { type: 'text/html' })
    saveFile(blob, FileNames.newsLetter)
  }

  useEffect(() => {
    buildHTML()
    location.reload()
  }, [])

  return (
<div ref={divRef} style={{ display: 'none' }}>
{chapters.map((chapter, index) => events.filter(pageObj => pageObj.chapterId === chapter.id).length > 0
  ? <RenderChapter
  key={index}
  chapterNameObj={chapter.htmlRenderName ?? chapter.name}
  scheduleType={scheduleType}
  chapterDataArr={events.filter(event => event.chapterId === chapter.id)}
  introId={chapter.introId}
  />
  : null)}
</div>)
}
