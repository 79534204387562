import React from 'react'
import Separator from './Separator'
import parse from 'html-react-parser'

interface Props {
  chapterName: string
}

export default function ChapterHeader (props: Props): JSX.Element {
  const { chapterName } = props
  return (
    <>
    <table
      cellPadding={0}
      cellSpacing={0}
      style={{
        borderCollapse: 'collapse',
        fontSize: 14,
        lineHeight: '1.5',
        width: '100%'
      }}
      border={0}
      width="100%"
    >
      <tbody>
        <tr style={{ borderColor: 'transparent' }}>
          <th
            style={{
              borderColor: 'transparent',
              fontWeight: 400,
              textAlign: 'left',
              verticalAlign: 'top'
            }}
            className="tc"
            align="left"
          >
            <table
              border={0}
              width="100%"
              cellPadding={0}
              cellSpacing={0}
              style={{
                borderCollapse: 'collapse',
                fontSize: 14,
                lineHeight: '1.5',
                backgroundColor: '#fff',
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0
              }}
            >
              <tbody>
                <tr style={{ borderColor: 'transparent' }}>
                  <td
                    style={{
                      borderCollapse: 'collapse',
                      borderColor: 'transparent'
                    }}
                  >
                    <table
                      width="100%"
                      cellPadding={0}
                      cellSpacing={0}
                      id="wout_block_out_block_104"
                      style={{
                        borderCollapse: 'separate',
                        fontSize: 14,
                        lineHeight: '1.5',
                        fontWeight: 'normal',
                        margin: 0,
                        overflow: 'hidden'
                      }}
                    >
                      <tbody>
                        <tr
                          className="content-row"
                          style={{
                            borderColor: 'transparent',
                            color: '#444',
                            fontFamily:
                              'Arial, "Helvetica Neue", Helvetica, sans-serif'
                          }}
                        >
                          <td
                            className="content-cell"
                            width={570}
                            style={{
                              borderCollapse: 'collapse',
                              borderColor: 'transparent',
                              verticalAlign: 'top',
                              paddingLeft: 15,
                              paddingRight: 15,
                              paddingTop: 15,
                              paddingBottom: 15
                            }}
                            valign="top"
                          >
                            <h3
                              style={{
                                lineHeight: '1.2',
                                margin: '0 0 10px',
                                fontWeight: 'normal',
                                fontSize: 24,
                                color: '#444',
                                fontFamily:
                                  'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                textAlign: 'center'
                              }}
                            >
                              <span style={{ fontSize: 24, color: '#ff6600' }}>
                                <strong>{ parse(chapterName) }</strong>
                              </span>
                            </h3>
                            <div
                              style={{
                                fontSize: 14,
                                lineHeight: '1.5',
                                clear: 'both'
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </th>
        </tr>
      </tbody>
    </table>
    <Separator/>
    </>
  )
}
