import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { chapters } from '../../../params/mailing'
import { MailProps } from '../../../Pages/MailingPage'
import { UlEvent } from '../../../types/DTO/UlEvent.dto'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'

export default function SetChapter (props: MailProps): JSX.Element {
  const { globalIndex: index, eventsDTO, setEventsDTO, scheduleType } = props

  const setChapter = (e: any): void => {
    const newEventsDTO: UlEvent[] = [...eventsDTO]

    newEventsDTO[index].chapterId = e.target.value
    setEventsDTO(newEventsDTO)
  }

  // useEffect(() => { setChapter({ target: { value: chapter } }) }, [])

  return (
    <FormControl style={{ flex: 1, marginBottom: 10, marginRight: 10, minWidth: 200 }}>
      <InputLabel id="chapterSelectLabel">Раздел рассылки</InputLabel>
        <Select

        label="Раздел рассылки"
        labelId="chapterSelectLabel"
        id="chapterSelect"
        value={ eventsDTO[index].chapterId }
        onChange={setChapter}
        size='small'
        >
        {chapters.map((chapter, index: number) => <MenuItem
        key={index}
        value={chapter.id}
        >{chapter.name.default ?? chapter.name[scheduleType] }
        </MenuItem>
        )
        }
        </Select>
        </FormControl>
  )
}
