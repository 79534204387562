/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { GetData, GetDataWParams } from '../../types/types'

const getTnAtomAll = (pageDOM: HTMLElement): HTMLElement[] => Array.from(pageDOM.querySelectorAll('div.tn-atom'))

export const getByTriggers: GetDataWParams = (pageDOM, { triggers }) => {
  const isTriggerFound = (trigger: string): boolean => getTnAtomAll(pageDOM).filter((el: HTMLElement) => el.textContent === undefined || el.textContent === null
    ? false
    : (el.textContent.includes(trigger))).length > 0

  return triggers.map((trigger: string) => isTriggerFound(trigger)
    ? getTnAtomAll(pageDOM).filter((el: HTMLElement) => el.textContent!.includes(trigger))[0]
      .textContent!.replace(trigger, '')
      .trim()
    : '').filter(item => item)[0] || ''
}

export const getTitle: GetData = (pageDOM) => pageDOM.querySelector<HTMLElement>('h1.tn-atom')!.textContent!

export const getLectorName: GetData = (pageDOM) => {
  // в массиве tnAtomAll убираем все пустые элементы и ищем (el === 'Лектор') (НЕ el.includes('лектор')!).
  // Если такой найден - получаем его index. А имя лектора ожидаем по предыдущему индексу.

  const allAtomContentArray = getTnAtomAll(pageDOM).map(el => el.textContent).filter(el => el)
  const lectorIndex = allAtomContentArray.indexOf('Лектор')
  return lectorIndex !== -1 ? allAtomContentArray[lectorIndex - 1]!.trim() : ''
}

export const getDescription: GetData = (pageDOM) => {
  // pageDOM.querySelector<HTMLElement>('div[field="text"]')!.textContent!
  const descriptionDirty = pageDOM.querySelector<HTMLElement>('div[field="text"]')?.innerHTML
  const result = descriptionDirty !== undefined
    ? descriptionDirty
      .replaceAll(/<(?!br\s*\/?)[^>]+>/g, '')
      .replaceAll('<br>', '\n')
      // .replaceAll('<br><br>', '<br>')

    : ''

  return result
}

export const getImageURL: GetData = (pageDOM) => pageDOM.querySelector<HTMLElement>('div.t-bgimg')?.dataset.original ?? ''

export const getDate: GetDataWParams = (pageDOM, { triggers }) => {
  const dateDirty = getByTriggers(pageDOM, { triggers }) // 29.03 - 19.04 || 20 февраля 2023 г.

  const thisYear = new Date().getFullYear().toString()
  const yearIndex = dateDirty.search(thisYear)
  return yearIndex === -1
    ? dateDirty // если год не найден - возвращаем как есть (Нужно для курсов, где формат даты "29.03 - 19.04")
    : dateDirty.slice(0, dateDirty.search(thisYear) - 1) // 20 февраля 2023 г. → 20 февраля
}
