interface MailingInputEventTypeObj {
  name: string
  renderName?: string
  fetchPreset: string
  csvName?: string
}

export enum MailingEventType {
  EVENT = 'event',
  SERIALEVENT = 'serialEvent',
  LECTURE = 'lecture',
  WEBINAR = 'webinar',
  EXCURSION = 'excursion',
  SPB = 'spb',
  COURSE = 'course'
}

export const mailingEventTypes: Record<MailingEventType, MailingInputEventTypeObj> = {
  [MailingEventType.EVENT]: { name: 'Мероприятие', fetchPreset: 'singleEvent' },
  [MailingEventType.SERIALEVENT]: { name: 'Путешествие', fetchPreset: 'longTermEvent' },
  [MailingEventType.LECTURE]: { name: 'Лекция', fetchPreset: 'singleEvent' },
  [MailingEventType.WEBINAR]: { name: 'Вебинар', fetchPreset: 'singleEvent' },
  [MailingEventType.EXCURSION]: { name: 'Экскурсия', fetchPreset: 'singleEvent' },
  [MailingEventType.SPB]: { name: 'Санкт-Петербург', fetchPreset: 'singleEvent', csvName: '' },
  [MailingEventType.COURSE]: { name: 'Лекционный курс', fetchPreset: 'longTermEvent' }
}

export interface ChapterObj {
  id: Chapter
  name: Record<string, string>
  htmlRenderName?: Record<string, string>
  availableEventTypes: MailingEventType[]
  introId?: string
}

export enum Chapter {
  SPB = 'spb',
  WEEKLY = 'weekly',
  LIVELECTURE = 'livelecture',
  COMINGSOON = 'comingSoon',
  COURSES = 'courses',
}

export const chapters: ChapterObj[] = [
  {
    id: Chapter.SPB,
    name: { default: 'Уникальные авторские путешествия в Санкт-Петербург' },
    htmlRenderName: { default: 'Уникальные авторские путешествия<br />в Санкт-Петербург' },
    availableEventTypes: [MailingEventType.SERIALEVENT],
    introId: 'spbIntro'
  },
  {
    id: Chapter.WEEKLY,
    name: { default: 'Расписание (неделя / выходные)' },
    htmlRenderName: { weekly: 'Расписание на неделю', weekEnd: 'Расписание на выходные' },
    availableEventTypes: [MailingEventType.EVENT, MailingEventType.WEBINAR, MailingEventType.EXCURSION, MailingEventType.SPB]
  },
  {
    id: Chapter.LIVELECTURE,
    name: { default: 'Живые лекции' },
    availableEventTypes: [MailingEventType.LECTURE]
  },
  {
    id: Chapter.COMINGSOON,
    name: { default: 'Скоро: авторские экскурсии и лекции' },
    availableEventTypes: [MailingEventType.EXCURSION, MailingEventType.LECTURE]
  },
  {
    id: Chapter.COURSES,
    name: { default: 'Курсы' },
    availableEventTypes: [MailingEventType.COURSE]
  }
]

type MapChapterIdToIntro = Record<string, string>

export const chapterIntroByIntroId: MapChapterIdToIntro = {
  spbIntro: `<p>Приглашаем Вас провести незабываемые выходные и насладиться неповторимой атмосферой старинного Петербурга.</p>
  <p>Вас ждут невероятные экскурсии в сопровождении гидов-историков, увлекательные рассказы и роскошные особняки, а также незабываемые впечатления от многогранной истории Петербурга!</p>`
}

export const mapEventTypeToChapterId: Record<MailingEventType, Chapter> = {
  [MailingEventType.SERIALEVENT]: Chapter.SPB,
  [MailingEventType.SPB]: Chapter.SPB,
  [MailingEventType.WEBINAR]: Chapter.WEEKLY,
  [MailingEventType.EVENT]: Chapter.WEEKLY,
  [MailingEventType.EXCURSION]: Chapter.WEEKLY,
  [MailingEventType.LECTURE]: Chapter.LIVELECTURE,
  [MailingEventType.COURSE]: Chapter.COURSES
}
