import React from 'react'

interface Props {
  date: string
  time: string
}

export default function Date (props: Props): JSX.Element {
  const { date, time } = props
  return (
    <p
      style={{
        lineHeight: 'inherit',
        margin: '0 0 10px',
        fontSize: 'inherit',
        color: 'inherit',
        fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
        fontWeight: 'normal',
        padding: 0
      }}
    >
      <span style={{ color: '#0000ff', fontSize: 24 }}>
        <b>
          <span>{ date }{time !== '' ? ` в ${time}` : ''}</span>
        </b>
      </span>
    </p>
  )
}
