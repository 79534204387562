import React, { useState, useRef } from 'react'
import logo from '../assets/ul_logo.png'
import { appVersion } from '../params/constants'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import {
  deletePageStorage,
  exportStorageToFile,
  importStorageFromFile
} from '../utils/LS/storage'
import { isCacheDataValid } from '../utils/LS/lsUtils'
import ModalYesNo from './Modals/ModalYesNo'
import { Notify } from '../types/types'

interface Props {
  notify: Notify
}

export default function TopBar (props: Props): JSX.Element {
  const {
    notify
  } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showModalDeleteStorage, setShowModalDeleteStorage] = useState(false)

  const inputFile = useRef<HTMLInputElement | null>(null)

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setShowModalDeleteStorage(false)
    setAnchorEl(null)
  }

  const handleImportStorage = (input: any): void => {
    const file = input.target.files[0]
    const reader = new FileReader()
    reader.onload = function () {
      const text = reader.result
      console.log(text)

      if (isCacheDataValid(text)) {
        importStorageFromFile(text)
        notify.success('Импортировали успешно')
      } else {
        notify.error('Файл не валидный или пустой')
      }
      handleClose()
    }
    reader.readAsText(file)
  }

  const handleOpenFile = (): void => {
    if (inputFile.current !== null) {
      inputFile.current.click()
    }
  }

  const handleDeleteStorage = (): void => {
    deletePageStorage()
    notify.success('Хранилище очищено')
    handleClose()
  }
  const handleExportStorage = (): void => {
    exportStorageToFile()
    notify.success('Экспортировали базу в файл')
    setAnchorEl(null)
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <input
        accept="json"
        type="file"
        id="file"
        value=""
        ref={inputFile}
        onChange={handleImportStorage}
        style={{ display: 'none' }}
      />

      <ModalYesNo
        show={showModalDeleteStorage}
        handleNo={handleClose}
        handleYes={handleDeleteStorage}
        title="Очистить хранилище?"
        textYes="Да"
        textNo="Отмена"
      />

      <AppBar position="static">
        <Toolbar
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%'
            }}
          >
            <img
              src={logo}
              width="130"
              style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}
            /> <Typography
            sx={{
              fontSize: 10,
              marginBottom: '0.6rem',
              opacity: 0.6,
              display: 'flex',
              alignSelf: 'end'
            }}
          >
            v.{appVersion}
          </Typography>

          </div>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              sx={{ marginTop: 5 }}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >

              <Typography sx={{ padding: 2, fontWeight: 'bold' }}>
                Хранилище
              </Typography>

              <MenuItem onClick={handleOpenFile}>
                <FolderOpenIcon sx={{ marginRight: 2 }} />
                Импорт
              </MenuItem>
              <MenuItem onClick={handleExportStorage}>
                <FileDownloadIcon sx={{ marginRight: 2 }} />
                Экспорт
              </MenuItem>
              <hr/>
              <MenuItem
                sx={{ color: 'red' }}
                onClick={() => {
                  setShowModalDeleteStorage(true)
                }}
              >
                <HighlightOffIcon sx={{ color: 'red', marginRight: 2 }} />
                Очистить всё
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
