
import { Chapter, MailingEventType } from '../../params/mailing'

export interface AdditionalDate {
  url: string
  timeStart: string
  dateStart: string
  dateEnd: string
  formattedDate: string
}

export enum Lectortitle {
  Lector = 'Лектор',
  Guide = 'Гид',
}

export interface UlEventRaw {

  imgUrl: string
  url: string
  timeStart: string
  dateStart: string
  dateEnd: string
  formattedDate: string
  title: string
  city: string
  price: string
  length: string
  eventType: MailingEventType
  lectorName: string
  lectorTitle?: Lectortitle
  allDates?: AdditionalDate[]
  description: string
  imgsPath: string
  hasImagesIndex: boolean
}

export interface UlEvent extends UlEventRaw {
  cachedDescription: string
  fetchedDescription: string
  isPremiere: boolean
  chapterId: Chapter

  isUnfetched?: boolean
}
