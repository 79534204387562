import { LsPrefixes, FileNames } from '../../params/constants'
import { LSEventDTO } from '../../types/DTO/LSEvent.dto'
import { UlEvent } from '../../types/DTO/UlEvent.dto'
import saveFile from '../RenderFiles/saveFile'
import { getLSKeyFromUrl } from './lsUtils'

export const deletePageStorage = (): void => {
  const cachedPagesKeysStr = localStorage.getItem(LsPrefixes.keysPages)
  const cachedPresetKeysStr = localStorage.getItem(LsPrefixes.keysPresets)

  if (cachedPagesKeysStr !== null) {
    const cachedPagesKeysArr: string[] = JSON.parse(cachedPagesKeysStr)
    cachedPagesKeysArr.forEach(key => { localStorage.removeItem(key) })
    localStorage.removeItem(LsPrefixes.keysPages)
  }

  if (cachedPresetKeysStr !== null) {
    const cachedPresetsKeysArr: string[] = JSON.parse(cachedPresetKeysStr)
    cachedPresetsKeysArr.forEach(key => { localStorage.removeItem(`${LsPrefixes.prefixPreset}${key}`) })
    localStorage.removeItem(LsPrefixes.keysPresets)
  }
}

export const importStorageFromFile = (data: any): void => {
  deletePageStorage()

  const parsedCacheObj = JSON.parse(data.slice(LsPrefixes.CACHEFILE_Header.length))

  localStorage.setItem(LsPrefixes.keysPages, JSON.stringify(parsedCacheObj[LsPrefixes.keysPages]))
  parsedCacheObj[LsPrefixes.keysPages].forEach((key: string, index: number) => {
    localStorage.setItem(key, parsedCacheObj[LsPrefixes.CACHEFILE_PagesArrayKey][index])
  })

  localStorage.setItem(LsPrefixes.keysPresets, JSON.stringify(parsedCacheObj[LsPrefixes.keysPresets]))
  parsedCacheObj[LsPrefixes.keysPresets].forEach((key: string, index: number) => {
    localStorage.setItem(`${LsPrefixes.prefixPreset}${key}`, parsedCacheObj[LsPrefixes.CACHEFILE_PresetsArrayKey][index])
  })
}

export const exportStorageToFile = (): void => {
  const cachedPagesKeysStr: string = localStorage.getItem(LsPrefixes.keysPages) ?? '[]'
  const cachedPagesKeysArr: string[] = JSON.parse(cachedPagesKeysStr)
  const cachedPagesDataArr = cachedPagesKeysArr.map((key: string) => localStorage.getItem(key))

  const presetsKeysStr: string = localStorage.getItem(LsPrefixes.keysPresets) ?? '[]'
  const presetsKeysArr: string[] = JSON.parse(presetsKeysStr)
  const presetsDataArr = presetsKeysArr.map((key: string) => localStorage.getItem(`${LsPrefixes.prefixPreset}${key}`))

  const blobObj = {
    [LsPrefixes.keysPages]: cachedPagesKeysArr,
    [LsPrefixes.keysPresets]: presetsKeysArr,

    [LsPrefixes.CACHEFILE_PagesArrayKey]: cachedPagesDataArr,
    [LsPrefixes.CACHEFILE_PresetsArrayKey]: presetsDataArr
  }
  const blob = new Blob([`${LsPrefixes.CACHEFILE_Header}${JSON.stringify(blobObj)}`], { type: 'application/json;charset=utf-8' })

  saveFile(blob, FileNames.cachedDataExport)
}

export const updateLS = (eventsDTO: UlEvent[]): void => {
  const cachedPagesKeysStr: string = localStorage.getItem(LsPrefixes.keysPages) ?? '[]'
  const cachedPagesKeysArr: string[] = JSON.parse(cachedPagesKeysStr)

  eventsDTO.forEach(eventDTO => {
    const lSEvent: LSEventDTO = {
      eventType: eventDTO.eventType,
      chapterId: eventDTO.chapterId,
      description: eventDTO.description,
      isPremiere: eventDTO.isPremiere
    }

    const lsKey = getLSKeyFromUrl(eventDTO.url)
    cachedPagesKeysArr.push(lsKey)
    localStorage.setItem(lsKey, JSON.stringify(lSEvent))
  })

  localStorage.setItem(LsPrefixes.keysPages, JSON.stringify(cachedPagesKeysArr))
}
