import React, { useEffect } from 'react'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import {
  mailingEventTypes,
  chapters,
  MailingEventType
} from '../../../params/mailing'
import { MailProps } from '../../../Pages/MailingPage'
import { UlEvent } from '../../../types/DTO/UlEvent.dto'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'

export default function SetEventType (props: MailProps): JSX.Element {
  const { globalIndex: index, eventsDTO, setEventsDTO } = props

  const availableEventTypes = chapters.filter(
    (chapter) => chapter.id === eventsDTO[index].chapterId
  )[0].availableEventTypes

  const eventType: MailingEventType =
    availableEventTypes.length > 1
      ? eventsDTO[index].eventType
      : availableEventTypes[0]

  const setType = (e: any): void => {
    const newEventsDTO: UlEvent[] = [...eventsDTO]

    newEventsDTO[index].eventType = e.target.value
    setEventsDTO(newEventsDTO)
  }

  useEffect(() => {
    setType({ target: { value: eventType } })
  }, [])

  return (
    <FormControl>
      <InputLabel id="eventTypeSelectLabel">Тип мероприятия</InputLabel>
      <Select
        style={{ flex: 0, marginBottom: 10, marginRight: 10, minWidth: 200 }}
        error={!availableEventTypes.includes(eventType)}
        label="Тип мероприятия"
        labelId="eventTypeSelectLabel"
        id="eventTypeSelect"
        value={eventType}
        onChange={setType}
        size="small"
      >
        {availableEventTypes.map(
          (chapterEventTypeKey: MailingEventType, index: number) => (
            <MenuItem key={index} value={chapterEventTypeKey}>
              {mailingEventTypes[chapterEventTypeKey].name}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  )
}
