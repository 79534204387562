import { ScheduleType, scheduleTypes } from '../../params/schedule'

export default (dates: string, scheduleType: ScheduleType): string => `<div style="font-size:14px; line-height:1.5; background-color:#eee" bgcolor="#eeeeee">
<table class="wrapper-table" cellpadding="5" cellspacing="0" width="100%" border="0" style="border-collapse:collapse; font-size:14px; line-height:1.5">
    <!--[if gte mso 9]><v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t"><v:fill type="frame" color="#eeeeee"/>        </v:background><![endif]-->
    <tbody>
        <tr style="border-color:transparent">
            <td align="center" style="border-collapse:collapse; border-color:transparent">
                <table cellpadding="0" cellspacing="0" width="600px" id="bodyTable" border="0" bgcolor="#ffffff" style="border-collapse:collapse; font-size:14px; line-height:1.5">
                    <tbody>
                        <tr style="border-color:transparent">
                            <td border="0" cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                                <table cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; width:100%" border="0" width="100%">
                                    <tbody>
                                        <tr style="border-color:transparent">
                                            <th width="600" style="border-color:transparent; font-weight:400; text-align:left; vertical-align:top" cellpadding="0" cellspacing="0" class="tc" align="left" valign="top">
                                                <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#eee; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#eeeeee">
                                                    <tbody>
                                                        <tr style="border-color:transparent">
                                                            <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                                                                <table width="100%" cellpadding="0" cellspacing="0" id="wout_block_out_block_1" style="border-collapse:separate; font-size:14px; line-height:1.5; text-color:black; font-weight:normal; margin:0; overflow:hidden">
                                                                    <tbody>
                                                                        <tr class="content-row" style="border-color:transparent; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif">
                                                                            <td class="content-cell padding-lr-0" width="600" style="border-collapse:collapse; border-color:transparent; vertical-align:top; padding-left:0; padding-right:0; padding-top:15px; padding-bottom:15px" valign="top">
                                                                                <p style="line-height:1.2; margin:0 0 10px; font-size:inherit; color:inherit; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; text-align:center; font-weight:normal; padding:0" align="center"><span style="font-size: 12px; color: #808080;"><a href="{{webversion}}" style="text-decoration:none; color:inherit">Просмотреть сообщение онлайн</a></span>
                                                                                </p>
                                                                                <div style="font-size:14px; line-height:1.5; clear:both">
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                                <table cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; width:100%" border="0" width="100%">
                                    <tbody>
                                        <tr style="border-color:transparent">
                                            <th width="600" style="border-color:transparent; font-weight:400; text-align:left; vertical-align:top" cellpadding="0" cellspacing="0" class="tc" align="left" valign="top">
                                                <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#fff; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#ffffff">
                                                    <tbody>
                                                        <tr style="border-color:transparent">
                                                            <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                                                                <table width="100%" cellpadding="0" cellspacing="0" id="wout_block_2_element_0" style="border-collapse:separate; font-size:14px; line-height:1.5; overflow:hidden">
                                                                    <tbody>
                                                                        <tr class="content-row" style="border-color:transparent; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif">
                                                                            <td class="content-cell" width="570" style="border-collapse:collapse; border-color:transparent; vertical-align:top; padding-left:15px; padding-right:15px; padding-top:15px; padding-bottom:15px" valign="top">
                                                                                <div id="wout_block_2_element_0" style="font-size:14px; line-height:1.5; width:100%; height:47; display:block; text-align:center" width="100%" height="47" align="center">
                                                                                    <center><img border="0" width="122" height="auto" class="desktop  sp-img small_img " align="center" alt="logotip_umnye_lyudi" src="https://s7457482.sendpul.se/files/emailservice/userfiles/a273b194cab3431f1e2bef2da16206967457482/logotip_umnye_lyudi.png" iout_block_2_element_0="" style="height:auto; line-height:100%; outline:0; text-decoration:none; border:0; display:block; -ms-interpolation-mode:bicubic"><!--[if !mso]><!-->
                                                                                        <div style="font-size:14px; line-height:1.5; mso-hide:all">
                                                                                            <img border="0" width="100%" height="auto" class="mobile  sp-img small_img " align="center" alt="logotip_umnye_lyudi" src="https://s7457482.sendpul.se/files/emailservice/userfiles/a273b194cab3431f1e2bef2da16206967457482/logotip_umnye_lyudi.png" iout_block_2_element_0="" style="height:auto; line-height:100%; outline:0; text-decoration:none; border:0; -ms-interpolation-mode:bicubic; display:none; width:100%; max-width:129px !important">
                                                                                        </div><!--<![endif]-->
                                                                                    </center>
                                                                                </div>
                                                                                <div style="font-size:14px; line-height:1.5; clear:both">
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                                <table cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; width:100%" border="0" width="100%">
                                    <tbody>
                                        <tr style="border-color:transparent">
                                            <th width="200" style="border-color:transparent; font-weight:400; text-align:left; vertical-align:top" cellpadding="0" cellspacing="0" class="tc" align="left" valign="top">
                                                <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#fff; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#ffffff">
                                                    <tbody>
                                                        <tr style="border-color:transparent">
                                                            <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                                                                <table width="100%" cellpadding="0" cellspacing="0" style="border-collapse:separate; font-size:14px; line-height:1.5; height:70px; overflow:hidden" height="70">
                                                                    <tbody>
                                                                        <tr class="content-row" style="border-color:transparent; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif">
                                                                            <td class="content-cell" width="170" height="40" style="border-collapse:collapse; border-color:transparent; vertical-align:top; padding-left:15px; padding-right:15px; padding-top:15px; padding-bottom:15px" valign="top">
                                                                                <center>
                                                                                    <table cellpadding="0" border="0" cellspacing="0" class="sp-button flat auto-width" style="border-collapse:collapse; font-size:14px; line-height:1.5; border:0; margin-left:auto; margin-right:auto; width:auto !important; border-radius:5px; box-shadow:none; background:transparent" width="auto !important">
                                                                                        <tbody>
                                                                                            <tr style="border-color:transparent">
                                                                                                <td class="sp-button-text" style="border-collapse:collapse; border-color:transparent; border-width:0; border-style:none; border:0; padding:0; align:center; border-radius:5px; width:auto; height:40px; vertical-align:middle; text-align:center" width="auto" height="40" valign="middle" align="center">
                                                                                                    <table cellpadding="0" border="0" cellspacing="0" width="100%" style="border-collapse:collapse; font-size:14px; line-height:1.5; border:0">
                                                                                                        <tbody>
                                                                                                            <tr style="border-color:transparent">
                                                                                                                <td align="center" style="border-collapse:collapse; border-color:transparent; border:0; padding:0; line-height:1">
                                                                                                                    <a style="text-decoration:none; color:#333; display:block; padding:12px 18px; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; font-family-short:arial; font-size:16px; font-weight:bold" href="https://umnyeludi.ru/">Главная</a>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </center>
                                                                                <div style="font-size:14px; line-height:1.5; clear:both">
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th>
                                            <th width="200" style="border-color:transparent; font-weight:400; text-align:left; vertical-align:top" cellpadding="0" cellspacing="0" class="tc" align="left" valign="top">
                                                <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#fff; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#ffffff">
                                                    <tbody>
                                                        <tr style="border-color:transparent">
                                                            <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                                                                <table width="100%" cellpadding="0" cellspacing="0" style="border-collapse:separate; font-size:14px; line-height:1.5; overflow:hidden">
                                                                    <tbody>
                                                                        <tr class="content-row" style="border-color:transparent; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif">
                                                                            <td class="content-cell" width="170" style="border-collapse:collapse; border-color:transparent; vertical-align:top; padding-left:15px; padding-right:15px; padding-top:15px; padding-bottom:15px" valign="top">
                                                                                <center>
                                                                                    <table cellpadding="0" border="0" cellspacing="0" class="sp-button flat auto-width" style="border-collapse:collapse; font-size:14px; line-height:1.5; border:0; margin-left:auto; margin-right:auto; width:auto !important; border-radius:5px; box-shadow:none; background:transparent" width="auto !important">
                                                                                        <tbody>
                                                                                            <tr style="border-color:transparent">
                                                                                                <td class="sp-button-text" style="border-collapse:collapse; border-color:transparent; border-width:0; border-style:none; border:0; padding:0; align:center; border-radius:5px; width:auto; height:40px; vertical-align:middle; text-align:center" width="auto" height="40" valign="middle" align="center">
                                                                                                    <table cellpadding="0" border="0" cellspacing="0" width="100%" style="border-collapse:collapse; font-size:14px; line-height:1.5; border:0">
                                                                                                        <tbody>
                                                                                                            <tr style="border-color:transparent">
                                                                                                                <td align="center" style="border-collapse:collapse; border-color:transparent; border:0; padding:0; line-height:1">
                                                                                                                    <a style="text-decoration:none; color:#333; display:block; padding:12px 18px; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; font-family-short:arial; font-size:16px; font-weight:bold" href="https://umnyeludi.ru/timetable">Расписание</a>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </center>
                                                                                <div style="font-size:14px; line-height:1.5; clear:both">
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th>
                                            <th width="200" style="border-color:transparent; font-weight:400; text-align:left; vertical-align:top" cellpadding="0" cellspacing="0" class="tc" align="left" valign="top">
                                                <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#fff; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#ffffff">
                                                    <tbody>
                                                        <tr style="border-color:transparent">
                                                            <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                                                                <table width="100%" cellpadding="0" cellspacing="0" style="border-collapse:separate; font-size:14px; line-height:1.5; overflow:hidden">
                                                                    <tbody>
                                                                        <tr class="content-row" style="border-color:transparent; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif">
                                                                            <td class="content-cell" width="170" style="border-collapse:collapse; border-color:transparent; vertical-align:top; padding-left:15px; padding-right:15px; padding-top:15px; padding-bottom:15px" valign="top">
                                                                                <center>
                                                                                    <table cellpadding="0" border="0" cellspacing="0" class="sp-button flat auto-width" style="border-collapse:collapse; font-size:14px; line-height:1.5; border:0; margin-left:auto; margin-right:auto; width:auto !important; border-radius:5px; box-shadow:none; background:transparent" width="auto !important">
                                                                                        <tbody>
                                                                                            <tr style="border-color:transparent">
                                                                                                <td class="sp-button-text" style="border-collapse:collapse; border-color:transparent; border-width:0; border-style:none; border:0; padding:0; align:center; border-radius:5px; width:auto; height:40px; vertical-align:middle; text-align:center" width="auto" height="40" valign="middle" align="center">
                                                                                                    <table cellpadding="0" border="0" cellspacing="0" width="100%" style="border-collapse:collapse; font-size:14px; line-height:1.5; border:0">
                                                                                                        <tbody>
                                                                                                            <tr style="border-color:transparent">
                                                                                                                <td align="center" style="border-collapse:collapse; border-color:transparent; border:0; padding:0; line-height:1">
                                                                                                                    <a style="text-decoration:none; color:#333; display:block; padding:12px 18px; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; font-family-short:arial; font-size:16px; font-weight:bold" href="https://umnyeludi.ru/faq">Вопрос-ответ</a>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </center>
                                                                                <div style="font-size:14px; line-height:1.5; clear:both">
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                                <table cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; width:100%" border="0" width="100%">
                                    <tbody>
                                        <tr style="border-color:transparent">
                                            <th width="600" style="border-color:transparent; font-weight:400; text-align:left; vertical-align:top" cellpadding="0" cellspacing="0" class="tc" align="left" valign="top">
                                                <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#fff; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#ffffff">
                                                    <tbody>
                                                        <tr style="border-color:transparent">
                                                            <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                                                                <div class="block-divider" style="font-size:14px; line-height:1.5; padding-left:15px; padding-right:15px; padding-top:15px; padding-bottom:15px">
                                                                    <hr id="iout_block_126_element_0" style="margin:0; border-top-style:solid; border-top-width:1px; border-top-color:#B2B2B2; border-bottom:0; border-left:0; border-right:0">
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                                <table cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; width:100%" border="0" width="100%">
                                    <tbody>
                                        <tr style="border-color:transparent">
                                            <th width="300" style="border-color:transparent; font-weight:400; text-align:left; vertical-align:top" cellpadding="0" cellspacing="0" class="tc" align="left" valign="top">
                                                <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#fff; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#ffffff">
                                                    <tbody>
                                                        <tr style="border-color:transparent">
                                                            <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                                                                <table width="100%" cellpadding="0" cellspacing="0" id="wout_block_out_block_109" style="border-collapse:separate; font-size:14px; line-height:1.5; text-color:black; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; font-family-short:arial; font-weight:normal; margin:0; overflow:hidden">
                                                                    <tbody>
                                                                        <tr class="content-row" style="border-color:transparent; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif">
                                                                            <td class="content-cell" width="270" style="border-collapse:collapse; border-color:transparent; vertical-align:top; padding-left:15px; padding-right:15px; padding-top:15px; padding-bottom:15px" valign="top">
                                                                                <h3 style="line-height:1.5; margin:0 0 10px; font-weight:normal; font-size:24px; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; text-align:left" align="left"><span style="font-size: 18px;"><strong>Экскурсии
                                                                                            и
                                                                                            вебинары</strong></span>
                                                                                </h3>
                                                                                <h3 align="left" style="line-height:1.5; margin:0 0 10px; font-weight:normal; font-size:24px; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; text-align:left">
                                                                                    <span style="font-size: 18px;"><strong>${dates}</strong></span>
                                                                                </h3>
                                                                                <h3 align="left" style="line-height:1.5; margin:0 0 10px; font-weight:normal; font-size:24px; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; text-align:left">
                                                                                    <span style="font-size: 18px;"><strong>с
                                                                                            лекторием «Умные
                                                                                            люди»</strong></span>
                                                                                </h3>
                                                                                <p style="line-height:1.5; margin:0 0 10px; font-size:inherit; color:inherit; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; font-weight:normal; padding:0">
                                                                                    <span>${scheduleTypes[scheduleType].renderPhrase}.</span></p>
                                                                                <div style="font-size:14px; line-height:1.5; clear:both">
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#fff; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#ffffff">
                                                    <tbody>
                                                        <tr style="border-color:transparent">
                                                            <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                                                                <table width="100%" cellpadding="0" cellspacing="0" style="border-collapse:separate; font-size:14px; line-height:1.5; overflow:hidden">
                                                                    <tbody>
                                                                        <tr class="content-row" style="border-color:transparent; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif">
                                                                            <td class="content-cell" width="270" style="border-collapse:collapse; border-color:transparent; vertical-align:top; padding-left:15px; padding-right:15px; padding-top:15px; padding-bottom:15px" valign="top">
                                                                                <table cellpadding="0" border="0" cellspacing="0" align="left" class="sp-button flat auto-width" style="border-collapse:collapse; font-size:14px; line-height:1.5; border-color:#ddd; border-width:1px; border-style:solid; border:0; width:auto !important; border-radius:5px; box-shadow:none; background:#f0cd5b" width="auto !important">
                                                                                    <tbody>
                                                                                        <tr style="border-color:transparent">
                                                                                            <td class="sp-button-text" style="border-collapse:collapse; border-color:transparent; padding:0; border-width:0; border-style:none; border:0; align:left; border-radius:5px; width:auto; height:40px; vertical-align:middle; text-align:center" width="auto" height="40" valign="middle" align="center">
                                                                                                <table cellpadding="0" border="0" cellspacing="0" width="100%" style="border-collapse:collapse; font-size:14px; line-height:1.5; border:0">
                                                                                                    <tbody>
                                                                                                        <tr style="border-color:transparent">
                                                                                                            <td align="center" style="border-collapse:collapse; border-color:transparent; padding:0; border:0; line-height:1">
                                                                                                                <a style="text-decoration:none; color:#333; display:block; padding:12px 18px; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; font-family-short:arial; font-size:16px; font-weight:bold" href="https://umnyeludi.ru/timetable">Посмотреть
                                                                                                                    расписание</a>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                                <div style="font-size:14px; line-height:1.5; clear:both">
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th>
                                            <th width="300" style="border-color:transparent; font-weight:400; text-align:left; vertical-align:top" cellpadding="0" cellspacing="0" class="tc" align="left" valign="top">
                                                <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#fff; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#ffffff">
                                                    <tbody>
                                                        <tr style="border-color:transparent">
                                                            <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                                                                <table width="100%" cellpadding="0" cellspacing="0" id="wout_block_110_element_0" style="border-collapse:separate; font-size:14px; line-height:1.5; overflow:hidden">
                                                                    <tbody>
                                                                        <tr class="content-row" style="border-color:transparent; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif">
                                                                            <td class="content-cell" width="270" style="border-collapse:collapse; border-color:transparent; vertical-align:top; padding-left:15px; padding-right:15px; padding-top:15px; padding-bottom:15px" valign="top">
                                                                                <div id="wout_block_110_element_0" style="font-size:14px; line-height:1.5; width:100%; height:253; display:block" width="100%" height="253">
                                                                                    <img border="0" width="270" height="auto" class="desktop  sp-img small_img " align="right" alt="57161623240251101" src="https://s7457482.sendpul.se/files/emailservice/userfiles/a273b194cab3431f1e2bef2da16206967457482/57161623240251101.png" iout_block_110_element_0="" style="height:auto; line-height:100%; outline:0; text-decoration:none; border:0; margin:0; display:block; -ms-interpolation-mode:bicubic"><!--[if !mso]><!-->
                                                                                    <div style="font-size:14px; line-height:1.5; mso-hide:all">
                                                                                        <img border="0" width="100%" height="auto" class="mobile  sp-img small_img " align="right" alt="57161623240251101" src="https://s7457482.sendpul.se/files/emailservice/userfiles/a273b194cab3431f1e2bef2da16206967457482/57161623240251101.png" iout_block_110_element_0="" style="height:auto; line-height:100%; outline:0; text-decoration:none; border:0; -ms-interpolation-mode:bicubic; display:none; width:100%; max-width:100% !important">
                                                                                    </div><!--<![endif]-->
                                                                                </div>
                                                                                <div style="font-size:14px; line-height:1.5; clear:both">
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                                <table cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; width:100%" border="0" width="100%">
                                    <tbody>
                                        <tr style="border-color:transparent">
                                            <th width="600" style="border-color:transparent; font-weight:400; text-align:left; vertical-align:top" cellpadding="0" cellspacing="0" class="tc" align="left" valign="top">
                                                <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#fff; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#ffffff">
                                                    <tbody>
                                                        <tr style="border-color:transparent">
                                                            <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                                                                <div class="block-divider" style="font-size:14px; line-height:1.5; padding-left:15px; padding-right:15px; padding-top:15px; padding-bottom:15px">
                                                                    <hr id="iout_block_119_element_0" style="margin:0; border-top-style:solid; border-top-width:1px; border-top-color:#B2B2B2; border-bottom:0; border-left:0; border-right:0">
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                                <table cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; width:100%" border="0" width="100%">
                                    <tbody>
                                        <tr style="border-color:transparent">
                                            <th width="600" style="border-color:transparent; font-weight:400; text-align:left; vertical-align:top" cellpadding="0" cellspacing="0" class="tc" align="left" valign="top">
                                                <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#fff; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#ffffff">
                                                    <tbody>
                                                        <tr style="border-color:transparent">
                                                            <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                                                                <table width="100%" cellpadding="0" cellspacing="0" id="wout_block_out_block_112" style="border-collapse:separate; font-size:14px; line-height:1.5; text-color:black; font-weight:normal; margin:0; overflow:hidden">
                                                                    <tbody>
                                                                        <tr class="content-row" style="border-color:transparent; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif">
                                                                            <td class="content-cell" width="570" style="border-collapse:collapse; border-color:transparent; vertical-align:top; padding-left:15px; padding-right:15px; padding-top:15px; padding-bottom:15px" valign="top">
                                                                                <p style="line-height:inherit; margin:0 0 10px; font-size:inherit; color:inherit; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; font-weight:normal; padding:0">
                                                                                    <span style="font-size: 18px;">Приглашаем
                                                                                        вас подписаться на наш
                                                                                        телеграм-канал
                                                                                        Umnyeludi2022 и на наше
                                                                                        сообщество Вконтакте,
                                                                                        чтобы быть в курсе
                                                                                        нашего
                                                                                        расписания!</span></p>
                                                                                <div style="font-size:14px; line-height:1.5; clear:both">
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                                <table cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; width:100%" border="0" width="100%">
                                    <tbody>
                                        <tr style="border-color:transparent">
                                            <th width="300" style="border-color:transparent; font-weight:400; text-align:left; vertical-align:top" cellpadding="0" cellspacing="0" class="tc" align="left" valign="top">
                                                <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#fff; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#ffffff">
                                                    <tbody>
                                                        <tr style="border-color:transparent">
                                                            <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                                                                <table width="100%" cellpadding="0" cellspacing="0" id="wout_block_113_element_0" style="border-collapse:separate; font-size:14px; line-height:1.5; overflow:hidden">
                                                                    <tbody>
                                                                        <tr class="content-row" style="border-color:transparent; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif">
                                                                            <td class="content-cell" width="270" style="border-collapse:collapse; border-color:transparent; vertical-align:top; padding-left:15px; padding-right:15px; padding-top:15px; padding-bottom:15px" valign="top">
                                                                                <div id="wout_block_113_element_0" style="font-size:14px; line-height:1.5; width:100%; height:253; display:block" width="100%" height="253"><a class="link_img" href="https://vk.com/lectoriumumnyeludi" style="text-decoration:none; color:#0089bf; display:block"><img border="0" width="270" height="auto" class="desktop  sp-img small_img " align="left" alt="Vkontakte" src="https://s7457482.sendpul.se/files/emailservice/userfiles/a273b194cab3431f1e2bef2da16206967457482/Vkontakte.jpg" iout_block_113_element_0="" style="height:auto; line-height:100%; outline:0; text-decoration:none; border:0; margin:0; display:block; -ms-interpolation-mode:bicubic"><!--[if !mso]><!-->
                                                                                        <div style="font-size:14px; line-height:1.5; mso-hide:all">
                                                                                            <img border="0" width="100%" height="auto" class="mobile  sp-img small_img " align="left" alt="Vkontakte" src="https://s7457482.sendpul.se/files/emailservice/userfiles/a273b194cab3431f1e2bef2da16206967457482/Vkontakte.jpg" iout_block_113_element_0="" style="height:auto; line-height:100%; outline:0; text-decoration:none; border:0; -ms-interpolation-mode:bicubic; display:none; width:100%; max-width:416px !important">
                                                                                        </div><!--<![endif]-->
                                                                                    </a></div>
                                                                                <div style="font-size:14px; line-height:1.5; clear:both">
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#fff; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#ffffff">
                                                    <tbody>
                                                        <tr style="border-color:transparent">
                                                            <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                                                                <table width="100%" cellpadding="0" cellspacing="0" style="border-collapse:separate; font-size:14px; line-height:1.5; overflow:hidden">
                                                                    <tbody>
                                                                        <tr class="content-row" style="border-color:transparent; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif">
                                                                            <td class="content-cell" width="270" style="border-collapse:collapse; border-color:transparent; vertical-align:top; padding-left:15px; padding-right:15px; padding-top:5px; padding-bottom:15px" valign="top">
                                                                                <center>
                                                                                    <table cellpadding="0" border="0" cellspacing="0" class="sp-button flat auto-width" style="border-collapse:collapse; font-size:14px; line-height:1.5; border:0; margin-left:auto; margin-right:auto; width:auto !important; border-radius:5px; box-shadow:none; background:#f0cd5b" width="auto !important">
                                                                                        <tbody>
                                                                                            <tr style="border-color:transparent">
                                                                                                <td class="sp-button-text" style="border-collapse:collapse; border-color:transparent; border-width:0; border-style:none; border:0; padding:0; align:center; border-radius:5px; width:auto; height:40px; vertical-align:middle; text-align:center" width="auto" height="40" valign="middle" align="center">
                                                                                                    <table cellpadding="0" border="0" cellspacing="0" width="100%" style="border-collapse:collapse; font-size:14px; line-height:1.5; border:0">
                                                                                                        <tbody>
                                                                                                            <tr style="border-color:transparent">
                                                                                                                <td align="center" style="border-collapse:collapse; border-color:transparent; border:0; padding:0; line-height:1">
                                                                                                                    <a style="text-decoration:none; color:#333; display:block; padding:13.5px 20.25px; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; font-family-short:arial; font-size:13px; font-weight:bold" href="https://vk.com/lectoriumumnyeludi">Подписаться</a>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </center>
                                                                                <div style="font-size:14px; line-height:1.5; clear:both">
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th>
                                            <th width="300" style="border-color:transparent; font-weight:400; text-align:left; vertical-align:top" cellpadding="0" cellspacing="0" class="tc" align="left" valign="top">
                                                <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#fff; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#ffffff">
                                                    <tbody>
                                                        <tr style="border-color:transparent">
                                                            <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                                                                <table width="100%" cellpadding="0" cellspacing="0" id="wout_block_114_element_0" style="border-collapse:separate; font-size:14px; line-height:1.5; overflow:hidden">
                                                                    <tbody>
                                                                        <tr class="content-row" style="border-color:transparent; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif">
                                                                            <td class="content-cell" width="270" style="border-collapse:collapse; border-color:transparent; vertical-align:top; padding-left:15px; padding-right:15px; padding-top:15px; padding-bottom:15px" valign="top">
                                                                                <div id="wout_block_114_element_0" style="font-size:14px; line-height:1.5; width:100%; height:253; display:block" width="100%" height="253"><a class="link_img" href="https://t.me/Umnyeludi2022" style="text-decoration:none; color:#0089bf; display:block"><img border="0" width="270" height="auto" class="desktop  sp-img small_img " align="left" alt="Telegram-kanal" src="https://s7457482.sendpul.se/files/emailservice/userfiles/a273b194cab3431f1e2bef2da16206967457482/Telegram-kanal.jpg" iout_block_114_element_0="" style="height:auto; line-height:100%; outline:0; text-decoration:none; border:0; margin:0; display:block; -ms-interpolation-mode:bicubic"><!--[if !mso]><!-->
                                                                                        <div style="font-size:14px; line-height:1.5; mso-hide:all">
                                                                                            <img border="0" width="100%" height="auto" class="mobile  sp-img small_img " align="left" alt="Telegram-kanal" src="https://s7457482.sendpul.se/files/emailservice/userfiles/a273b194cab3431f1e2bef2da16206967457482/Telegram-kanal.jpg" iout_block_114_element_0="" style="height:auto; line-height:100%; outline:0; text-decoration:none; border:0; -ms-interpolation-mode:bicubic; display:none; width:100%; max-width:416px !important">
                                                                                        </div><!--<![endif]-->
                                                                                    </a></div>
                                                                                <div style="font-size:14px; line-height:1.5; clear:both">
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#fff; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#ffffff">
                                                    <tbody>
                                                        <tr style="border-color:transparent">
                                                            <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                                                                <table width="100%" cellpadding="0" cellspacing="0" style="border-collapse:separate; font-size:14px; line-height:1.5; overflow:hidden">
                                                                    <tbody>
                                                                        <tr class="content-row" style="border-color:transparent; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif">
                                                                            <td class="content-cell" width="270" style="border-collapse:collapse; border-color:transparent; vertical-align:top; padding-left:15px; padding-right:15px; padding-top:5px; padding-bottom:15px" valign="top">
                                                                                <center>
                                                                                    <table cellpadding="0" border="0" cellspacing="0" class="sp-button flat auto-width" style="border-collapse:collapse; font-size:14px; line-height:1.5; border:0; margin-left:auto; margin-right:auto; width:auto !important; border-radius:5px; box-shadow:none; background:#f0cd5b" width="auto !important">
                                                                                        <tbody>
                                                                                            <tr style="border-color:transparent">
                                                                                                <td class="sp-button-text" style="border-collapse:collapse; border-color:transparent; border-width:0; border-style:none; border:0; padding:0; align:center; border-radius:5px; width:auto; height:40px; vertical-align:middle; text-align:center" width="auto" height="40" valign="middle" align="center">
                                                                                                    <table cellpadding="0" border="0" cellspacing="0" width="100%" style="border-collapse:collapse; font-size:14px; line-height:1.5; border:0">
                                                                                                        <tbody>
                                                                                                            <tr style="border-color:transparent">
                                                                                                                <td align="center" style="border-collapse:collapse; border-color:transparent; border:0; padding:0; line-height:1">
                                                                                                                    <a style="text-decoration:none; color:#333; display:block; padding:13.5px 20.25px; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; font-family-short:arial; font-size:13px; font-weight:bold" href="https://t.me/Umnyeludi2022">Подписаться</a>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </center>
                                                                                <div style="font-size:14px; line-height:1.5; clear:both">
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                                <table cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; width:100%" border="0" width="100%">
                                    <tbody>
                                        <tr style="border-color:transparent">
                                            <th width="600" style="border-color:transparent; font-weight:400; text-align:left; vertical-align:top" cellpadding="0" cellspacing="0" class="tc" align="left" valign="top">
                                                <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#fff; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#ffffff">
                                                    <tbody>
                                                        <tr style="border-color:transparent">
                                                            <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                                                                <div class="block-divider" style="font-size:14px; line-height:1.5; padding-left:15px; padding-right:15px; padding-top:15px; padding-bottom:15px">
                                                                    <hr id="iout_block_183_element_0" style="margin:0; border-top-style:solid; border-top-width:1px; border-top-color:#B2B2B2; border-bottom:0; border-left:0; border-right:0">
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table> `
