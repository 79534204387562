import React from 'react'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { ModalBox, ModalContainer } from './style/ModalsStyle'

interface Props {
  title: string
  textYes: string
  textNo: string
  show: boolean
  handleYes: () => void
  handleNo: () => void
}

export default function ModalYesNo (props: Props): JSX.Element {
  const { title, handleYes, handleNo, show, textNo, textYes } = props

  return <Modal open={show}
          sx={ModalContainer}
           onClose={handleNo}
           aria-labelledby="modal-modal-title"
           aria-describedby="modal-modal-description" >
      <Box sx={ModalBox}>
          <Typography id="modal-modal-title" variant="h5" component="h3">
            {title}
          </Typography>
          <Button variant='contained' color='error' sx={{ margin: 1, marginTop: 3 }} onClick={handleYes}>{textYes}</Button>
          <Button variant='contained' sx={{ margin: 1, marginTop: 3 }} onClick={handleNo}>{textNo}</Button>
        </Box></Modal>
}
