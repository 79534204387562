import React, { useState, useEffect } from 'react'
import selectIUniqueImages from '../../../utils/Images/selectIUniqueImages'
import { TemplateProps } from '../../../Components/Modals/ModalImageGenerator'
import '../style.css'
import TitleDate from '../vertical/TitleDate'

const SQ_1 = (props: TemplateProps): JSX.Element => {
  const { title, date, photosUrl, photosArr, params } = props

  const [selectedImages, setSelectedImages] = useState<string[]>([])

  useEffect(() => {
    const images = selectIUniqueImages(photosArr, 1)
    setSelectedImages(images)
  }, [photosArr])

  const photoBgrUrl0 = `url(${photosUrl}${selectedImages[0]})`

  return (
    <div className="templateMainContainer" style={params.style}>
      <div
        className="bgrImage"
        style={{
          backgroundImage: photoBgrUrl0,
          top: 0,
          left: 0,
          width: 1080,
          height: 700,
          display: 'block'
        }}
      ></div>
    <TitleDate title={title} date={date} style={{ position: 'absolute', top: 720, left: 0, width: 1080, height: 360 }}/>
    </div>
  )
}

export default SQ_1
