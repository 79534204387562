export default `<table width="100%" style="border-collapse:collapse;font-size:14px;line-height:1.5;width:100%" cellspacing="0"
cellpadding="0" border="0">
<tbody>
    <tr style="border-color:transparent">
        <td valign="top"
            style="border-collapse:collapse;border-color:transparent;padding-left:0;padding-right:0;padding-top:0;padding-bottom:0;vertical-align:top"
            cellspacing="0" cellpadding="0" border="0">
            <table width="100%" style="border-collapse:collapse;font-size:14px;line-height:1.5;width:100%"
                cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr style="border-color:transparent">
                        <th width="600" valign="top"
                            style="border-color:transparent;font-weight:400;text-align:left;vertical-align:top"
                            cellspacing="0" cellpadding="0" align="left">
                            <table width="100%"
                                style="border-collapse:collapse;font-size:14px;line-height:1.5;border-top-right-radius:0;border-top-left-radius:0;border-bottom-left-radius:0;border-bottom-right-radius:0"
                                cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr style="border-color:transparent">
                                        <td valign="top"
                                            style="border-collapse:collapse;border-color:transparent;vertical-align:top"
                                            cellspacing="0" cellpadding="0">
                                            <table width="100%"
                                                style="border-collapse:separate;font-size:14px;line-height:1.5;overflow:hidden"
                                                cellspacing="0" cellpadding="0">
                                                <tbody>
                                                    <tr
                                                        style="border-color:transparent;color:#444;font-family:Arial,&quot;Helvetica Neue&quot;,Helvetica,sans-serif">
                                                        <td width="570" valign="top"
                                                            style="border-collapse:collapse;border-color:transparent;vertical-align:top;padding-left:15px;padding-right:15px;padding-top:15px;padding-bottom:15px">
                                                            <div>
                                                                <table width="auto !important"
                                                                    style="border-collapse:collapse;font-size:14px;line-height:1.5;border:0;margin-left:auto;margin-right:auto;width:auto!important;border-radius:5px;background-color:#ebc940;background:linear-gradient(180deg,#ebc940 0%,#ebc940 85%,#d7b117 100%)"
                                                                    cellspacing="0" cellpadding="0" border="0"
                                                                    bgcolor="#ebc940">
                                                                    <tbody>
                                                                        <tr style="border-color:transparent">
                                                                            <td width="auto" valign="middle"
                                                                                style="border-collapse:collapse;border-color:transparent;border-width:0;border-style:none;border:0;padding:0;border-radius:5px;width:auto;height:40px;vertical-align:middle;text-align:center"
                                                                                height="40" align="center">
                                                                                <table width="100%"
                                                                                    style="border-collapse:collapse;font-size:14px;line-height:1.5;border:0"
                                                                                    cellspacing="0" cellpadding="0"
                                                                                    border="0">
                                                                                    <tbody>
                                                                                        <tr
                                                                                            style="border-color:transparent">
                                                                                            <td style="border-collapse:collapse;border-color:transparent;border:0;padding:0;line-height:1"
                                                                                                align="center"><a
                                                                                                    style="text-decoration:none;color:#333;display:block;padding:12px 18px;font-family:Arial,&quot;Helvetica Neue&quot;,Helvetica,sans-serif;font-size:16px;font-weight:bold"
                                                                                                    href="{{unsubscribe_url}}" target="_blank">Отписаться от рассылки</a>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div style="clear:both;font-size:14px;line-height:1.5">
                                                                &nbsp;</div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table width="100%"
                                style="border-collapse:collapse;font-size:14px;line-height:1.5;border-top-right-radius:0;border-top-left-radius:0;border-bottom-left-radius:0;border-bottom-right-radius:0"
                                cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr style="border-color:transparent">
                                        <td valign="top"
                                            style="border-collapse:collapse;border-color:transparent;vertical-align:top"
                                            cellspacing="0" cellpadding="0">
                                            <div
                                                style="font-size:14px;line-height:1.5;padding-bottom:15px;padding-left:15px;padding-right:15px;padding-top:15px">
                                                <hr style="margin:0;border-top-style:solid;border-top-width:1px;border-top-color:#b2b2b2;border-bottom:0;border-left:0;border-right:0"
                                                    id="m_9171616352412093297iout_block_429_element_0_mr_css_attr">
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </th>
                    </tr>
                </tbody>
            </table>
        </td>
    </tr>
</tbody>
</table>




<table cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; width:100%" border="0" width="100%">
<tbody>
    <tr style="border-color:transparent">
        <th width="200" style="border-color:transparent; font-weight:400; text-align:left; vertical-align:top" cellpadding="0" cellspacing="0" class="tc" align="left" valign="top">
            <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#fff; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#ffffff">
                <tbody>
                    <tr style="border-color:transparent">
                        <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                            <table width="100%" cellpadding="0" cellspacing="0" id="wout_block_2_element_0" style="border-collapse:separate; font-size:14px; line-height:1.5; overflow:hidden">
                                <tbody>
                                    <tr class="content-row" style="border-color:transparent; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif">
                                        <td class="content-cell padding-top-0 padding-bottom-0" width="185" style="border-collapse:collapse; border-color:transparent; vertical-align:top; padding-left:15px; padding-right:0; padding-top:0; padding-bottom:0" valign="top">
                                            <div id="wout_block_2_element_0" style="font-size:14px; line-height:1.5; width:100%; height:47; display:block" width="100%" height="47">
                                                <img border="0" width="122" height="auto" class="desktop  sp-img small_img " align="left" alt="logotip_umnye_lyudi" src="https://s7457482.sendpul.se/files/emailservice/userfiles/a273b194cab3431f1e2bef2da16206967457482/logotip_umnye_lyudi.png" iout_block_2_element_0="" style="height:auto; line-height:100%; outline:0; text-decoration:none; border:0; margin:0; display:block; -ms-interpolation-mode:bicubic"><!--[if !mso]><!-->
                                                <div style="font-size:14px; line-height:1.5; mso-hide:all">
                                                    <img border="0" width="100%" height="auto" class="mobile  sp-img small_img " align="left" alt="logotip_umnye_lyudi" src="https://s7457482.sendpul.se/files/emailservice/userfiles/a273b194cab3431f1e2bef2da16206967457482/logotip_umnye_lyudi.png" iout_block_2_element_0="" style="height:auto; line-height:100%; outline:0; text-decoration:none; border:0; -ms-interpolation-mode:bicubic; display:none; width:100%; max-width:129px !important">
                                                </div><!--<![endif]-->
                                            </div>
                                            <div style="font-size:14px; line-height:1.5; clear:both">
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#fff; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#ffffff">
                <tbody>
                    <tr style="border-color:transparent">
                        <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                            <table width="100%" cellpadding="0" cellspacing="0" id="wout_block_out_block_124" style="border-collapse:separate; font-size:14px; line-height:1.5; text-color:black; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; font-family-short:arial; font-weight:normal; margin:0; overflow:hidden">
                                <tbody>
                                    <tr class="content-row" style="border-color:transparent; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif">
                                        <td class="content-cell" width="170" style="border-collapse:collapse; border-color:transparent; vertical-align:top; padding-left:15px; padding-right:15px; padding-top:15px; padding-bottom:15px" valign="top">
                                            <p style="line-height:inherit; margin:0 0 10px; font-size:inherit; color:inherit; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; text-align:center; font-weight:normal; padding:0" align="center"><span style="font-size: 13px;"><a href="https://umnyeludi.ru/about" style="text-decoration:none; color:#0089bf">О
                                                        нас</a></span></p>
                                            <p style="line-height:inherit; margin:0 0 10px; font-size:inherit; color:inherit; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; text-align:center; font-weight:normal; padding:0" align="center"><span style="font-size: 13px;"><a href="https://umnyeludi.ru/timetable" style="text-decoration:none; color:#0089bf">Расписание</a></span>
                                            </p>
                                            <p style="line-height:inherit; margin:0 0 10px; font-size:inherit; color:inherit; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; text-align:center; font-weight:normal; padding:0" align="center"><span style="font-size: 13px;"><a href="https://umnyeludi.ru/lectors" style="text-decoration:none; color:#0089bf">Лекторы</a></span>
                                            </p>
                                            <p style="line-height:inherit; margin:0 0 10px; font-size:inherit; color:inherit; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; text-align:center; font-weight:normal; padding:0" align="center"><span style="font-size: 13px;"><a href="https://umnyeludi.ru/page11804989.html" style="text-decoration:none; color:#0089bf">Отзывы</a></span>
                                            </p>
                                            <p style="line-height:inherit; margin:0 0 10px; font-size:inherit; color:inherit; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; text-align:center; font-weight:normal; padding:0" align="center"><span style="font-size: 13px;"><a href="https://umnyeludi.ru/page11830112.html" style="text-decoration:none; color:#0089bf">Блог</a></span>
                                            </p>
                                            <div style="font-size:14px; line-height:1.5; clear:both">
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </th>
        <th width="400" style="border-color:transparent; font-weight:400; text-align:left; vertical-align:top" cellpadding="0" cellspacing="0" class="tc" align="left" valign="top">
            <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#fff; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#ffffff">
                <tbody>
                    <tr style="border-color:transparent">
                        <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                            <table width="100%" cellpadding="0" cellspacing="0" id="wout_block_out_block_129" style="border-collapse:separate; font-size:14px; line-height:1.5; text-color:black; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; font-family-short:arial; font-weight:normal; margin:0; overflow:hidden">
                                <tbody>
                                    <tr class="content-row" style="border-color:transparent; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif">
                                        <td class="content-cell padding-bottom-0" width="370" style="border-collapse:collapse; border-color:transparent; vertical-align:top; padding-left:15px; padding-right:15px; padding-top:15px; padding-bottom:0" valign="top">
                                            <p style="line-height:inherit; margin:0 0 10px; font-size:inherit; color:inherit; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; text-align:left; font-weight:normal; padding:0" align="left"><span style="font-size: 20px;"><strong>Контакты</strong></span>
                                            </p>
                                            <p style="line-height:inherit; margin:0 0 10px; font-size:inherit; color:inherit; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; text-align:left; font-weight:normal; padding:0" align="left"><a href="tel:+79857725016" style="text-decoration:none; color:#0089bf">+7
                                                    985 772 50 16</a></p>
                                            <p style="line-height:inherit; margin:0 0 10px; font-size:inherit; color:inherit; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; text-align:left; font-weight:normal; padding:0" align="left"><a href="mailto:hello@umnyeludi.ru" style="text-decoration:none; color:#0089bf">hello@umnyeludi.ru</a>
                                            </p>
                                            <div style="font-size:14px; line-height:1.5; clear:both">
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#fff; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#ffffff">
                <tbody>
                    <tr style="border-color:transparent">
                        <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                            <table width="100%" cellpadding="0" cellspacing="0" style="border-collapse:separate; font-size:14px; line-height:1.5; size:32px; order:1; text-align:left; overflow:hidden" align="left">
                                <tbody>
                                    <tr class="content-row" style="border-color:transparent; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif">
                                        <td class="content-cell padding-lr-0 padding-top-0 padding-bottom-0" width="400" style="border-collapse:collapse; border-color:transparent; vertical-align:top; padding-left:0; padding-right:0; padding-top:0; padding-bottom:0" valign="top">
                                            <table class="social" cellpadding="5" border="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1; border-color:transparent; border-width:0; border-style:none; border:0; display:inline-block; border-spacing:0">
                                                <tbody>
                                                    <tr style="border-color:transparent">
                                                        <th class="social_element social_element_h_i" style="border-color:transparent; padding:2px 5px; font-size:13px; font-family:Arial, sans-serif; line-height:32px; font-weight:400; text-align:left; border-width:0; border-style:none; border:0" align="left"><a href="https://t.me/Umnyeludi2022" style="text-decoration:none; color:#0089bf"><img border="0" alt="Telegram" class="social small_img smallImg" style="height:auto; line-height:100%; outline:0; text-decoration:none; border:0; border-color:transparent; border-width:0; border-style:none; display:block; margin:5px" vspace="5" hspace="5" title="Telegram" width="32" height="auto" src="https://s7457482.sendpul.se/img/constructor/social/round/telegram.png"></a>
                                                        </th>
                                                        <th class="social_element social_element_h_i" style="border-color:transparent; padding:2px 5px; font-size:13px; font-family:Arial, sans-serif; line-height:32px; font-weight:400; text-align:left; border-width:0; border-style:none; border:0" align="left"><a href="https://vk.com/lectoriumumnyeludi" style="text-decoration:none; color:#0089bf"><img border="0" alt="Вконтакте" class="social small_img smallImg" style="height:auto; line-height:100%; outline:0; text-decoration:none; border:0; border-color:transparent; border-width:0; border-style:none; display:block; margin:5px" vspace="5" hspace="5" title="Вконтакте" width="32" height="auto" src="https://s7457482.sendpul.se/img/constructor/social/round/vk.png"></a>
                                                        </th>
                                                        <th class="social_element social_element_h_i" style="border-color:transparent; padding:2px 5px; font-size:13px; font-family:Arial, sans-serif; line-height:32px; font-weight:400; text-align:left; border-width:0; border-style:none; border:0" align="left"><a href="https://www.youtube.com/channel/UCklK54IzWzmS3MHrqlqcuGA" style="text-decoration:none; color:#0089bf"><img border="0" alt="YouTube" class="social small_img smallImg" style="height:auto; line-height:100%; outline:0; text-decoration:none; border:0; border-color:transparent; border-width:0; border-style:none; display:block; margin:5px" vspace="5" hspace="5" title="YouTube" width="32" height="auto" src="https://s7457482.sendpul.se/img/constructor/social/round/youtube.png"></a>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div style="font-size:14px; line-height:1.5; clear:both">
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#fff; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#ffffff">
                <tbody>
                    <tr style="border-color:transparent">
                        <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                            <table width="100%" cellpadding="0" cellspacing="0" id="wout_block_out_block_127" style="border-collapse:separate; font-size:14px; line-height:1.5; text-color:black; background-repeat:repeat; background-position:left top; font-weight:normal; margin:0; overflow:hidden">
                                <tbody>
                                    <tr class="content-row" style="border-color:transparent; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif">
                                        <td class="content-cell padding-lr-0 padding-top-0 padding-bottom-0" width="400" style="border-collapse:collapse; border-color:transparent; vertical-align:top; padding-left:0; padding-right:0; padding-top:0; padding-bottom:0" valign="top">
                                            <p style="line-height:inherit; margin:0 0 10px; font-size:inherit; color:inherit; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; text-align:left; font-weight:normal; padding:0" align="left">
                                            <span style="font-size: 13px;">Вы получили это письмо, так как приобрели билет на экскурсию или вебинар. {{unsubscribe_url}} </span>
                                            </p>
                                            <div style="font-size:14px; line-height:1.5; clear:both">
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </th>
    </tr>
</tbody>
</table>
<table cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; width:100%" border="0" width="100%">
<tbody>
    <tr style="border-color:transparent">
        <th width="600" style="border-color:transparent; font-weight:400; text-align:left; vertical-align:top" cellpadding="0" cellspacing="0" class="tc" align="left" valign="top">
            <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#eee; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#eeeeee">
                <tbody>
                    <tr style="border-color:transparent">
                        <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                            <table width="100%" cellpadding="0" cellspacing="0" id="wout_block_out_block_8" style="border-collapse:separate; font-size:14px; line-height:1.5; text-color:black; font-weight:normal; margin:0; overflow:hidden">
                                <tbody>
                                    <tr class="content-row" style="border-color:transparent; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif">
                                        <td class="content-cell padding-top-0 padding-bottom-0" width="540" style="border-collapse:collapse; border-color:transparent; vertical-align:top; padding-left:30px; padding-right:30px; padding-top:0; padding-bottom:0" valign="top">
                                            <p style="line-height:inherit; margin:0 0 10px; font-size:inherit; color:inherit; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; text-align:center; font-weight:normal; padding:0" align="center"><span style="font-size: 13px; line-height: 19.5px;">©
                                                    Copyright, 2022, <a href="https://umnyeludi.ru/" style="text-decoration:none; color:#0089bf">Ваш
                                                        лекторий "Умные
                                                        люди"</a> •&nbsp;<a href="mailto:hello@umnyeludi.ru" style="text-decoration:none; color:#0089bf">hello@umnyeludi.ru</a></span>
                                            </p>
                                            <div style="font-size:14px; line-height:1.5; clear:both">
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table border="0" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse; font-size:14px; line-height:1.5; background-color:#eee; border-top-right-radius:0; border-top-left-radius:0; border-bottom-left-radius:0; border-bottom-right-radius:0" bgcolor="#eeeeee">
                <tbody>
                    <tr style="border-color:transparent">
                        <td cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-color:transparent">
                            <table width="100%" cellpadding="0" cellspacing="0" id="wout_block_out_block_10" style="border-collapse:separate; font-size:14px; line-height:1.5; text-color:black; font-weight:normal; margin:0; overflow:hidden">
                                <tbody>
                                    <tr class="content-row" style="border-color:transparent; color:#444; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif">
                                        <td class="content-cell padding-top-0" width="540" style="border-collapse:collapse; border-color:transparent; vertical-align:top; padding-left:30px; padding-right:30px; padding-top:0; padding-bottom:30px" valign="top">
                                            <p style="line-height:inherit; margin:0 0 10px; font-size:inherit; color:inherit; font-family:Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; text-align:center; font-weight:normal; padding:0" align="center"><span style="font-size: 13px;">Вы
                                                    получили данную рассылку, так как являетесь клиентом или подписчиком лектория "Умные люди". <a href="https://umnyeludi.ru/politika" style="text-decoration:none; color:#0089bf">Политика
                                                        конфиденциальности</a></span>
                                            </p>
                                            <div style="font-size:14px; line-height:1.5; clear:both">
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </th>
    </tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
<table width="600px" style="border-collapse:collapse; font-size:14px; line-height:1.5"></table>
</td>
</tr>
</tbody>
</table>
</div>`
