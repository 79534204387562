import React, { useState, useEffect } from 'react'
import selectIUniqueImages from '../../../utils/Images/selectIUniqueImages'
import { TemplateProps } from '../../../Components/Modals/ModalImageGenerator'
import '../style.css'
import TitleDate from './TitleDate'

const VERT_TD_1 = (props: TemplateProps): JSX.Element => {
  const { title, date, photosUrl, photosArr, params } = props

  const [selectedImages, setSelectedImages] = useState<string[]>([])

  useEffect(() => {
    const images = selectIUniqueImages(photosArr, 1)
    setSelectedImages(images)
  }, [photosArr])

  const photoBgrUrl0 = `url(${photosUrl}${selectedImages[0]})`

  return (
    <div className="templateMainContainer" style={params.style}>

<TitleDate title={title} date={date} style={{ position: 'absolute', top: 0, left: 0, width: 1080, height: 340 }}/>
      <div
        className="bgrImage"
        style={{
          backgroundImage: photoBgrUrl0,
          top: 340,
          left: 0,
          width: 1080,
          height: 1580
        }}
      ></div>
    </div>
  )
}

export default VERT_TD_1
