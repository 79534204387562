import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Notify } from './types/types'

import TopBar from './Components/TopBar'
import SchedulePage from './Pages/MailingPage'

const App = (): JSX.Element => {
  const notify: Notify = {
    success: (text: string): void => {
      toast.success(text, {
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        theme: 'light'
      })
    },
    error: (text: string): void => {
      toast.error(text, {
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        theme: 'light'
      })
    }
  }

  const topBarProps = { notify }
  const schedulePageProps = { notify }

  return (
    <div id="App" style={{ minWidth: 400 }}>
      <ToastContainer />
      <TopBar {...topBarProps} />
      <div id="container" style={{ maxWidth: '800px', padding: 30 }}>
        <SchedulePage {...schedulePageProps} />
      </div>
    </div>
  )
}

export default App
