import React, { CSSProperties } from 'react'
import useFitText from 'use-fit-text'

interface Props { date: string, style: CSSProperties }

const Date = (props: Props): JSX.Element => {
  const { date, style } = props
  const { fontSize, ref } = useFitText({ maxFontSize: 400, minFontSize: 10, resolution: 1 })
  return <div
    ref={ref}
    className="date"
    style={{ fontSize, ...style }}>{date}</div>
}

export default Date
