import { ScheduleType } from '../../params/schedule'

export default (date: string, scheduleType: ScheduleType, content: string): string => {
  type DocHeaders = Record<string, () => string>

  const docHeaders: DocHeaders = {
    weekEnd: (): string => `Представляем Вам расписание наших красивых и умных экскурсий, лекций, мероприятий и вебинаров, которые смогут украсить Ваши выходные – ${date} – Вам остается только выбрать!`,
    weekly: (): string => `Представляем Вам расписание наших красивых и умных экскурсий, лекций, мероприятий и вебинаров на текущую неделю – ${date}!`,
    monthly: (): string => `Представляем Вам расписание наших красивых и умных экскурсий, лекций, мероприятий и вебинаров на ${date}!`
  }

  return `<!DOCTYPE html>
<html lang="en">
  <head>
    
    <meta charset="utf-8" />
    <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />    
    
    <title>Лекторий «Умные люди»</title>
  </head>
  <body>    
    ${docHeaders[scheduleType]()}
    <br>
    <br>
    ${content}    
  </body>
</html>`
}
