import React from 'react'
import { ServerDto } from '../types/DTO/Server.dto'
import Alert from '@mui/material/Alert'

interface Props {
  statusDTO: ServerDto
  setStatusDTO: (props: ServerDto) => void
}

const updateAlert = (updatedHrsAgo: number | null = 0): JSX.Element => {
  const mapHoursToAlert = [
<Alert key={1} className="alert" severity="success">База актуальная</Alert>,
<Alert key={1} className="alert" severity="info">База обновлялась более 1 часа назад</Alert>,
<Alert key={2} className="alert" severity="warning">База обновлялась более 2 часов назад</Alert>,
<Alert key={3} className="alert" severity="error">База обновлялась более 3 часов назад</Alert>
  ]
  return updatedHrsAgo !== null && (updatedHrsAgo in mapHoursToAlert) ? mapHoursToAlert[updatedHrsAgo] : <Alert className="alert" severity="error">Похоже, база не обновляется</Alert>
}

const ServerAlerts = ({ statusDTO }: Props): JSX.Element => {
  return (
    <div style={{ marginBottom: '2rem' }}>{updateAlert(statusDTO.updatedHrsAgo) }</div>
  )
}

export default ServerAlerts
