import React from 'react'
import FormGroup from '@mui/material/FormGroup'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { MailProps } from '../../../Pages/MailingPage'
import { UlEvent } from '../../../types/DTO/UlEvent.dto'

const PremiereCheckBox = (props: MailProps): JSX.Element => {
  const { eventsDTO, setEventsDTO, globalIndex: index } = props

  const setPremiere = (): void => {
    const newEventsDTO: UlEvent[] = [...eventsDTO]
    newEventsDTO[index].isPremiere = !eventsDTO[index].isPremiere
    setEventsDTO(newEventsDTO)
  }

  return (
    <div style={{ flex: 0 }}>
    <FormGroup>
    <FormControlLabel control={<Checkbox checked={eventsDTO[index].isPremiere} onChange={setPremiere}/>} label="Премьера" />
    </FormGroup>
    </div>

  )
}

export default PremiereCheckBox
