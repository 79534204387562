import { domain } from '../../params/constants'
import { AdditionalDate, UlEvent } from '../../types/DTO/UlEvent.dto'

export const isUrlValid = (urlString: string): boolean => {
  let url
  try { url = new URL(urlString) } catch { return false }
  return (url.protocol === 'http:' || url.protocol === 'https:') && url.hostname === domain
}

// export const isUrlUnique = (urlString: string,
//   eventsDTO: UlEvent[] | AdditionalDate[]): boolean => {
//   const uniqueUrls = new Set()
//   eventsDTO.forEach(event => uniqueUrls.add(event.url))

//   return uniqueUrls.size === eventsDTO.length
// }

export const getUrlError = (urlString: string, eventsDTO: UlEvent[] | AdditionalDate[]): string => {
  if (urlString === '') { return 'URL пустой' }
  if (!isUrlValid(urlString)) { return 'URL некорректный' }
  // if (!isUrlUnique(urlString, eventsDTO)) { return 'Такой URL уже есть' }
  return ''
}

export const resizeImage = (url: string, width: number, height: number): string => {
  const imageUrlArr = url.split('/')
  imageUrlArr[imageUrlArr.length - 1] = `-/resize/${width}x${height}/${imageUrlArr[imageUrlArr.length - 1]}`
  const result = imageUrlArr.join('/')
  return result
}

export const getKeyFromUrl = (urlString: string): string => {
  const regex = /\d{4}[-_]\d{4}$/
  return new URL(urlString).pathname
    .slice(1)
    .replace(regex, '')
}
