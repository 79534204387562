import React, { useEffect, useState } from 'react'
import { domain } from '../../params/constants'
import { MailProps } from '../../Pages/MailingPage'
import { TextField } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { getUrlError } from '../../utils/URL/urlUtils'
import { UlEvent } from '../../types/DTO/UlEvent.dto'
import fetchSinglePage from '../../utils/DataGetters/fetchSinglePage'
import GetAppIcon from '@mui/icons-material/GetApp'
import ClearIcon from '@mui/icons-material/Clear'
import CircularProgress from '@mui/material/CircularProgress'

export default function RenderEmptyEvent (props: MailProps): JSX.Element {
  const { globalIndex: index, eventsDTO, setEventsDTO, chapterId, notify } = props

  const [state] = useState('input')
  const [error, setError] = useState<string | undefined>()
  const [newUrl, setNewUrl] = useState<string>('')
  const [fetching, setFetching] = useState<boolean>(false)

  useEffect(() => {
    setError(getUrlError(newUrl, eventsDTO))
  }, [newUrl, eventsDTO])

  const newEventsDTO = [...eventsDTO]

  const fetchEvent = async (): Promise<void> => {
    setFetching(true)
    try {
      const fetchedEvent: UlEvent = await fetchSinglePage(newUrl, chapterId)

      newEventsDTO[index] = fetchedEvent
      setEventsDTO(newEventsDTO)
      setFetching(false)
      notify.success('Мероприятие добавлено')
    } catch (e) {
      console.log(e)
      setError('Ошибка загрузки URL. Проверьте CORS')
      setFetching(false)
    }
  }

  const setUrl = (e: any): void => {
    setError(getUrlError(e.target.value, eventsDTO))
    setNewUrl(e.target.value)
  }

  const handleRemoveItem = (): void => {
    newEventsDTO.splice(index, 1)
    setEventsDTO(newEventsDTO)
  }

  return (
    <div style={{
      padding: '1rem',
      flexFlow: 'row wrap',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '2rem'
    }}>
      <IconButton
      aria-label="addItem"
      sx={{ border: '1px solid', flex: 0, width: '1rem', height: '1rem', marginRight: '0.5rem' }}
      size='small'
      color='error' onClick={handleRemoveItem}><ClearIcon
      sx={{ width: '1rem', height: '1rem' }}
      /></IconButton>
      <TextField
        id="outlined-basic"
        label={`https://${domain}/...`}
        variant="outlined"
        style={{ marginBottom: 10, marginRight: 10, display: 'flex', flex: 3 }}
        value={newUrl}
        size="small"
        error={Boolean(error)}
        helperText={error}
        onChange={setUrl}
        disabled={state === 'fetching'}

      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
      <IconButton
        sx={{ border: '1px solid', flex: 0, marginRight: '0.5rem', height: '100%', display: 'flex' }}
        aria-label="getPageDate"
        color="success"
        disabled={Boolean(getUrlError(newUrl, eventsDTO))}
        onClick={fetchEvent}
      >
        <GetAppIcon />
      </IconButton>
      {fetching && (<CircularProgress
            size={42}
            sx={{
              display: 'flex',
              flex: 0,
              marginTop: '-42px'
            }} />)}
</div>
    </div>
  )
}
