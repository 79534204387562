import React, { useState, useEffect } from 'react'
import selectIUniqueImages from '../../../utils/Images/selectIUniqueImages'
import { TemplateProps } from '../../../Components/Modals/ModalImageGenerator'
import '../style.css'
import TitleDate from '../vertical/TitleDate'

const SQ_4 = (props: TemplateProps): JSX.Element => {
  const { title, date, photosUrl, photosArr, params } = props

  const [selectedImages, setSelectedImages] = useState<string[]>([])

  useEffect(() => {
    const images = selectIUniqueImages(photosArr, 4)
    setSelectedImages(images)
  }, [photosArr])

  const photoBgrUrl0 = `url(${photosUrl}${selectedImages[0]})`
  const photoBgrUrl1 = `url(${photosUrl}${selectedImages[1]})`
  const photoBgrUrl2 = `url(${photosUrl}${selectedImages[2]})`
  const photoBgrUrl3 = `url(${photosUrl}${selectedImages[3]})`

  return (
    <div className="templateMainContainer" style={params.style}>

<div
        className="bgrImage"
        style={{
          backgroundImage: photoBgrUrl0,
          backgroundSize: 'cover',
          top: 0,
          left: 0,
          width: 535,
          height: 700,
          display: 'block'
        }}
      ></div>
      <div
        className="bgrImage"
        style={{
          backgroundImage: photoBgrUrl1,
          backgroundSize: 'cover',
          top: 0,
          left: 545,
          width: 535,
          height: 345,
          display: 'block'
        }}
      ></div>
<div
        className="bgrImage"
        style={{
          backgroundImage: photoBgrUrl2,
          backgroundSize: 'cover',
          top: 355,
          left: 545,
          width: 265,
          height: 345,
          display: 'block'
        }}
      ></div>
<div
        className="bgrImage"
        style={{
          backgroundImage: photoBgrUrl3,
          backgroundSize: 'cover',
          top: 355,
          left: 820,
          width: 260,
          height: 345,
          display: 'block'
        }}
      ></div>

<TitleDate title={title} date={date} style={{ position: 'absolute', top: 720, left: 0, width: 1080, height: 360 }}/>

    </div>
  )
}

export default SQ_4
