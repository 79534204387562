const withZeros = (string: string): string => string.length === 1 ? `0${string}` : string
const monthsRP = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря'
]

const monthsIP = [
  'январь',
  'февраль',
  'март',
  'апрель',
  'май',
  'июнь',
  'июль',
  'август',
  'сентябрь',
  'октябрь',
  'ноябрь',
  'декабрь'
]

export const dateToYYYYMMDD = (date: Date, separator: string): string => `${date.getFullYear()}${separator}${withZeros((date.getMonth() + 1).toString())}${separator}${withZeros((date.getDate()).toString())}`
export const dateToDDMMYYYY = (date: Date, separator: string): string => `${withZeros((date.getDate()).toString())}${separator}${withZeros((date.getMonth() + 1).toString())}${separator}${date.getFullYear()}`

export const datesRangeToReadableString = (startDate: Date, endDate: Date): string => {
  const sD = startDate.getDate()
  const sM = startDate.getMonth()

  if (!endDate) { return `${sD} ${monthsRP[sM]}` }
  const eD = endDate.getDate()
  const eM = endDate.getMonth()

  return `${sD} ${(sM !== eM) || (sD === eD) ? `${monthsRP[sM]} ` : ''}${sD !== eD ? `- ${eD} ${monthsRP[eM]}` : ''}`
}

export const datesToReadableString = (startDate: Date, endDate: Date): string => {
  return startDate.getTime() === endDate.getTime()
    ? `${(startDate.getDate()).toString()} ${monthsRP[(startDate.getMonth())]}`
    : datesRangeToReadableString(startDate, endDate)
}

export const guessMonthByDatesRange = (startDate: Date, endDate: Date): string => {
  if (startDate.getMonth() === endDate.getMonth()) { return monthsIP[startDate.getMonth()] }
  if (endDate.getMonth() - startDate.getMonth() === 2) { return monthsIP[startDate.getMonth() + 1] }
  return startDate.getDate() < 15 ? monthsIP[startDate.getMonth()] : monthsIP[endDate.getMonth()]
}

export const getDatesInRange = (startDate: Date, endDate: Date): Date[] => {
  const arr = []

  for (let date = new Date(startDate); date <= new Date(endDate); date.setDate(date.getDate() + 1)) {
    arr.push(new Date(date))
  }
  return arr
}

export const minsToHrs = (mins: number): string => {
  const result = Math.ceil(mins / 60)
  return (result > 0) ? `${result} ч.` : `${mins} мин.`
}

// export const ddmmToDate = (ddmmStr: string, separator: string = '.'): Date => {
//   const thisYear = new Date().getFullYear().toString()
//   const [dd, mm, yyyy] = ddmmStr.split(separator)
//   return new Date(`${yyyy ?? thisYear}-${mm}-${dd}`)
// }
