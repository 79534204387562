import axios from 'axios'
import { getDescription, getTitle, getByTriggers, getLectorName, getImageURL, getDate } from './dataGetters'
import { Lectortitle, UlEvent, UlEventRaw } from '../../types/DTO/UlEvent.dto'
import { Chapter, ChapterObj, chapters, mapEventTypeToChapterId } from '../../params/mailing'
import { getLSKeyFromUrl } from '../LS/lsUtils'
import { LSEventDTO } from '../../types/DTO/LSEvent.dto'

export const processFetchedEvent = (eventDTO: UlEventRaw, chapterId?: Chapter): UlEvent => {
  const resultDTO = {
    ...eventDTO,
    cachedDescription: '',
    fetchedDescription: eventDTO.description,
    chapterId: chapterId ?? Chapter.WEEKLY,
    isPremiere: false
  }

  const localStorageKey = getLSKeyFromUrl(eventDTO.url)
  const cachedPageData = localStorage.getItem(localStorageKey)

  if (cachedPageData !== null) {
    const parsedCache: LSEventDTO = JSON.parse(cachedPageData)
    resultDTO.cachedDescription = parsedCache.description
    resultDTO.eventType = parsedCache.eventType ?? eventDTO.eventType
    resultDTO.chapterId = parsedCache.chapterId ?? mapEventTypeToChapterId[eventDTO.eventType]
    resultDTO.isPremiere = parsedCache.isPremiere ?? false

    resultDTO.description = parsedCache.description
  } else {
    resultDTO.isPremiere = false
    resultDTO.chapterId = chapterId ?? mapEventTypeToChapterId[eventDTO.eventType]
  }

  return resultDTO
}

export default async (url: string, chapterId: Chapter = Chapter.WEEKLY): Promise<UlEvent> => {
  const currentChapterParams = chapters.find(chapter => chapter.id === chapterId) as ChapterObj

  try {
    const response = await axios.get(url)

    const pageDOM = document.createElement('pageDOM')
    pageDOM.innerHTML = response.data

    const resultedObj: UlEventRaw = {
      imgUrl: getImageURL(pageDOM),
      url,
      timeStart: getByTriggers(pageDOM, { triggers: ['Начало:'] }),
      dateStart: '',
      dateEnd: '',
      formattedDate: getDate(pageDOM, { triggers: ['Дата:', 'Даты проведения:'] }),
      title: getTitle(pageDOM),
      city: getByTriggers(pageDOM, { triggers: ['Место:'] }).replaceAll(' ', ''),
      price: getByTriggers(pageDOM, { triggers: ['Стоимость:'] }).replace('р.', '').trim(),
      length: getByTriggers(pageDOM, { triggers: ['Длительность:', 'Продолжительность:'] }),
      eventType: currentChapterParams.availableEventTypes[0],
      lectorName: getLectorName(pageDOM),
      lectorTitle: Lectortitle.Guide,
      description: getDescription(pageDOM),
      imgsPath: '',
      hasImagesIndex: false
    }

    return processFetchedEvent(resultedObj, chapterId)
  } catch (e: any) { throw new Error(e.message) }
}
