import React from 'react'

export default function Separator (): JSX.Element {
  return (
    <table
      cellPadding={0}
      cellSpacing={0}
      style={{
        fontSize: 14

      }}
      border={0}
      width="100%"
    >
      <tbody>
        <tr style={{ borderColor: 'transparent' }}>
          <th

            style={{
              borderColor: 'transparent',
              fontWeight: 400,
              textAlign: 'left',
              verticalAlign: 'top'
            }}
            className="tc"
            align="left"
          >
            <table
              border={0}
              width="100%"
              cellPadding={0}
              cellSpacing={0}
              style={{
                borderCollapse: 'collapse',
                fontSize: 14,
                lineHeight: '1.5',
                backgroundColor: '#fff',
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0
              }}

            >
              <tbody>
                <tr style={{ borderColor: 'transparent' }}>
                  <td
                    style={{
                      borderCollapse: 'collapse',
                      borderColor: 'transparent'
                    }}
                  >
                    <div
                      className="block-divider"
                      style={{
                        fontSize: 14,
                        lineHeight: '1.5',
                        paddingLeft: 15,
                        paddingRight: 15,
                        paddingTop: 15,
                        paddingBottom: 15
                      }}
                    >
                      <hr
                        id="iout_block_241_element_0"
                        style={{
                          margin: 0,
                          borderTopStyle: 'solid',
                          borderTopWidth: 1,
                          borderTopColor: '#B2B2B2',
                          borderBottom: 0,
                          borderLeft: 0,
                          borderRight: 0
                        }}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </th>
        </tr>
      </tbody>
    </table>
  )
}
