import React, { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { MailProps } from '../../../Pages/MailingPage'
import { AdditionalDate, UlEvent } from '../../../types/DTO/UlEvent.dto'
import Chip from '@mui/material/Chip'
import Popover from '@mui/material/Popover'
import TextField from '@mui/material/TextField'
import GetAppIcon from '@mui/icons-material/GetApp'
import { domain } from '../../../params/constants'
import { getUrlError } from '../../../utils/URL/urlUtils'
import fetchSinglePage from '../../../utils/DataGetters/fetchSinglePage'
import CircularProgress from '@mui/material/CircularProgress'

interface Props extends MailProps {
  dateIndex?: number
}

export default function ChipDate (props: Props): JSX.Element {
  const { globalIndex, eventsDTO, setEventsDTO, dateIndex } = props

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [newUrl, setNewUrl] = useState<string>('')
  const [error, setError] = useState<string | undefined>()
  const [fetching, setFetching] = useState<boolean>(false)

  useEffect(() => {
    setError(getUrlError(newUrl, eventsDTO))
  }, [newUrl, eventsDTO])

  const newEventsDTO = [...eventsDTO]
  const currentEventDTO = newEventsDTO[globalIndex]

  const getDateDTO = (eventDTO: UlEvent): AdditionalDate => ({
    url: eventDTO.url,
    timeStart: eventDTO.timeStart,
    dateStart: eventDTO.dateStart,
    dateEnd: eventDTO.dateEnd,
    formattedDate: eventDTO.formattedDate
  })

  const currentDateDTO: AdditionalDate =
    currentEventDTO.allDates !== undefined && dateIndex !== undefined
      ? currentEventDTO.allDates[dateIndex]
      : getDateDTO(currentEventDTO)

  const startEndStr = `${currentDateDTO.dateStart}${
    currentDateDTO.dateStart !== currentDateDTO.dateEnd
      ? `- ${currentDateDTO.dateEnd}`
      : null
  }`
  const timeStr = currentDateDTO.timeStart
    ? `(${currentDateDTO.timeStart})`
    : ''
  const chipLabel: string = `${
    currentDateDTO.formattedDate ?? startEndStr
  } ${timeStr}`

  const handleClickAddDate = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const openPopover = Boolean(anchorEl)
  const id = openPopover ? 'simple-popover' : undefined

  const doAddDate = async (): Promise<void> => {
    setFetching(true)
    try {
      const fetchedEvent = await fetchSinglePage(newUrl)
      const newDateDTO = getDateDTO(fetchedEvent)

      if (currentEventDTO.allDates !== undefined && dateIndex !== undefined) {
        currentEventDTO.allDates.splice(globalIndex + 1, 0, newDateDTO)
      } else {
        currentEventDTO.allDates = [currentDateDTO, newDateDTO]
      }
      setEventsDTO(newEventsDTO)
      setNewUrl('')
      setFetching(false)
      handleClose()
    } catch (e) {
      console.log(e)
      setError('Ошибка загрузки URL. Проверьте CORS')
      setFetching(false)
    }
  }

  const handleDeleteDate = (): void => {
    if (currentEventDTO.allDates !== undefined && dateIndex !== undefined) {
      currentEventDTO.allDates.splice(dateIndex, 1)
    }
    setEventsDTO(newEventsDTO)
  }

  const setUrl = (e: any): void => {
    setError(getUrlError(e.target.value, eventsDTO))
    setNewUrl(e.target.value)
  }

  return (
    <div style={{}}>
      <Chip
        label={chipLabel}
        size="small"
        onDelete={dateIndex ? handleDeleteDate : undefined}
      />

      <IconButton
        aria-label="addItem"
        color="success"
        size="small"
        style={{}}
        onClick={handleClickAddDate}
      >
        <AddCircleOutlineIcon sx={{ width: '1.1rem' }} />
      </IconButton>

      <Popover
        id={id}
        style={{ flex: 0, display: 'flex' }}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <div style={{ display: 'flex', flexFlow: 'row nowrap' }}>
          <TextField
            size="small"
            placeholder={`https://${domain}/...`}
            id="inputDateUrl"
            variant="outlined"
            style={{
              marginTop: '0.5rem',
              marginBottom: '0.5rem',
              marginLeft: '0.5rem',
              marginRight: '0.5rem'
            }}
            value={newUrl}
            error={Boolean(error)}
            helperText={error}
            onChange={setUrl}
          ></TextField>

          <div
            style={{
              display: 'flex',
              flex: 0,
              flexFlow: 'row nowrap',
              justifyContent: 'start',
              width: '50px'
            }}
          >
            <IconButton
              aria-label="getPageDate"
              color="success"
              style={{
                opacity: '0.8',
                flex: 0,
                marginTop: '5px',
                height: '42px'
              }}
              disabled={Boolean(getUrlError(newUrl, eventsDTO))}
              onClick={doAddDate}
            >
              <GetAppIcon fontSize="medium" />
            </IconButton>
            {fetching && (
              <CircularProgress
                size={42}
                sx={{
                  display: 'flex',
                  marginLeft: '-42px',
                  marginTop: '5px',
                  flex: 0
                }}
              />
            )}
          </div>
        </div>
      </Popover>
    </div>
  )
}
