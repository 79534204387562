import { LsPrefixes } from '../../params/constants'
import { getKeyFromUrl } from '../URL/urlUtils'

export const isJsonValid = (jsonData: string): boolean => {
  try { JSON.parse(jsonData) } catch (e) { return false }
  return true
}

export const isCacheDataValid = (data: string | null | ArrayBuffer): boolean => typeof data === 'string' &&
  data !== null &&
  data.startsWith(LsPrefixes.CACHEFILE_Header) &&
  isJsonValid(data.slice(LsPrefixes.CACHEFILE_Header.length))

export const getLSKeyFromUrl = (urlString: string): string => `${LsPrefixes.prefixPage}${getKeyFromUrl(urlString)}`
