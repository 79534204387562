import React from 'react'
import { MailPropsBase } from '../../Pages/MailingPage'
import RenderEvent from './RenderEvent'
import { UlEvent } from '../../types/DTO/UlEvent.dto'
import RenderEmptyEvent from './RenderEmptyEvent'
import '../../styles/chapter.css'
import ButtonAddItem from './Event/ButtonAddItem'
import { ChapterObj, chapters } from '../../params/mailing'
import { chapterBgColors } from '../../params/colors'

const RenderChapters = (props: MailPropsBase): JSX.Element => {
  const {
    eventsDTO
  } = props

  interface EventWithGlobalIndex {
    globalIndex: number
    event: UlEvent
  }
  type EventsByChapters = Record<string, EventWithGlobalIndex[]>
  const eventsByChapters: EventsByChapters = chapters.reduce((acc, chapterObj) => ({ ...acc, [chapterObj.id]: [] }), {})
  eventsDTO.forEach((event, index: number) => {
    eventsByChapters[event.chapterId].push({ event, globalIndex: index })
  })

  return (<>
    { chapters.map((chapter: ChapterObj, index: number) =>

    <div key={index} style={{ backgroundColor: chapterBgColors[index] }} className='chapterContainer'>
      <h2 >{chapter.name.default }</h2>

      <ButtonAddItem {...props} chapterId={chapter.id}/>
      {eventsByChapters[chapter.id].map((eventWIndex: EventWithGlobalIndex, chapterIndex: number) =>
        eventWIndex.event.isUnfetched !== undefined
          ? <RenderEmptyEvent
          key={chapterIndex}
          chapterLength={eventsByChapters[chapter.id].length}
          chapterId={chapter.id}
          globalIndex={eventWIndex.globalIndex}
          chapterIndex={chapterIndex}
          {...props}/>
          : <div key={chapterIndex}>
              <RenderEvent
              chapterId={chapter.id}
              globalIndex={eventWIndex.globalIndex}
              chapterIndex={chapterIndex}
              chapterLength={eventsByChapters[chapter.id].length}
              {...props}/>
              <ButtonAddItem {...props} index={eventWIndex.globalIndex} chapterId={chapter.id}/>
              </div>
      )}

      </div>)
    }

    </>
  )
}

export default RenderChapters
