import { MailingEventType, mailingEventTypes } from '../../params/mailing'
import { FileNames } from '../../params/constants'
import { dateToDDMMYYYY, getDatesInRange } from '../Formatters/dateFormatters'
import saveFile from './saveFile'
import { prepareForCsv } from '../Formatters/textFormatters'
import { UlEvent } from '../../types/DTO/UlEvent.dto'
import { CSVHeader } from '../../params/csv'

export default (events: UlEvent[], startDate: Date, endDate: Date): void => {
  const allDatesInRange = getDatesInRange(startDate, endDate).map(date => dateToDDMMYYYY(date, '.'))

  const mapKeyToProp = (event: UlEvent, key: CSVHeader): string => ({
    [CSVHeader.Type]: mailingEventTypes[event.eventType].csvName ?? mailingEventTypes[event.eventType].name,
    [CSVHeader.Title]: prepareForCsv(event.title),
    [CSVHeader.isNew]: '',
    [CSVHeader.Tag]: '',
    [CSVHeader.LectorName]: event.lectorName ? prepareForCsv(event.lectorName) : '',
    [CSVHeader.LectorTitle]: event.lectorTitle ?? '',
    [CSVHeader.Price]: event.price
  }[key] ?? '')

  const csvFirstLine = `${Object.values(CSVHeader).map(item => item)},${allDatesInRange.map(date => date)}\n`

  const renderEventProps = (event: UlEvent): string => `${Object.values(CSVHeader).map(item => mapKeyToProp(event, item))}`

  const renderEventLine = (event: UlEvent): string => {
    const eventTimeArray = new Array(allDatesInRange.length).fill('')
    const eventProps = renderEventProps(event)

    const setEventTimesByDates = (startDateStr: string, endDateStr: string, timeStart: string): void => {
      const eventStartDate = new Date(startDateStr)
      const eventEndDate = new Date(endDateStr)

      const eventDatesArr = getDatesInRange(eventStartDate, eventEndDate)

      eventDatesArr.forEach(date => {
        const dateIndex = allDatesInRange.indexOf(dateToDDMMYYYY(date, '.'))
        eventTimeArray[dateIndex] = timeStart
      })
    }

    const eventTimeMark = event.eventType === MailingEventType.SPB ? 'СПБ' : event.timeStart // исключение для Питера

    event.allDates !== undefined
      ? event.allDates.forEach(dateObj => { setEventTimesByDates(dateObj.dateStart, dateObj.dateEnd, dateObj.timeStart) })
      : setEventTimesByDates(event.dateStart, event.dateEnd, eventTimeMark ?? '')

    return `${eventProps},${eventTimeArray.map(timeSlot => timeSlot)}`
  }

  const result = `${csvFirstLine}${events.filter(event => event.eventType !== 'course')
    .map(event => renderEventLine(event)).join('\n')}`

  const blob = new Blob([result], { type: 'text/html' })
  saveFile(blob, FileNames.csvEvents)
}
