import React from 'react'
import RenderEventHTML from './RenderEventHTML'
import ChapterHeader from './Event/ChapterHeader'
import ChapterIntro from './Event/ChapterIntro'
import { UlEvent } from '../../../types/DTO/UlEvent.dto'

interface Props {
  chapterDataArr: UlEvent[]
  chapterNameObj: Record<string, string>
  introId?: string
  scheduleType: string
}

export default function RenderChapter ({ chapterDataArr, chapterNameObj, introId, scheduleType }: Props): JSX.Element {
  const chapterName = chapterNameObj.default ?? chapterNameObj[scheduleType]

  return <>
  <ChapterHeader chapterName={chapterName}/>
  {introId !== undefined ? <ChapterIntro introId={introId}/> : null}
    { chapterDataArr.map((event, index) => <RenderEventHTML key={index} event={event}/>) }
    </>
}
