import React from 'react'

interface Props {
  url: string
  date?: string
  time?: string | null
}

export default function Purchase (props: Props): JSX.Element {
  const { url, date, time } = props
  return (
    <table
      border={0}
      width="100%"
      cellPadding={0}
      cellSpacing={0}
      style={{
        borderCollapse: 'collapse',
        fontSize: 14,
        lineHeight: '1.5',
        backgroundColor: '#fff',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      }}
    >
      <tbody>
        <tr style={{ borderColor: 'transparent' }}>
          <td

            style={{ borderCollapse: 'collapse', borderColor: 'transparent' }}
          >
            <table
              width="100%"
              cellPadding={0}
              cellSpacing={0}
              style={{
                borderCollapse: 'separate',
                fontSize: 14,
                lineHeight: '1.5',
                overflow: 'hidden'
              }}
            >
              <tbody>
                <tr
                  className="content-row"
                  style={{
                    borderColor: 'transparent',
                    color: '#444',
                    fontFamily:
                      'Arial, "Helvetica Neue", Helvetica, sans-serif'
                  }}
                >
                  <td
                    className="content-cell"
                    width={270}
                    style={{
                      borderCollapse: 'collapse',
                      borderColor: 'transparent',
                      verticalAlign: 'top',
                      paddingLeft: 5,
                      paddingRight: 5,
                      paddingTop: 5,
                      paddingBottom: 5
                    }}
                    valign="top"
                  >
                    <table
                      cellPadding={0}
                      border={0}
                      cellSpacing={0}
                      align="left"
                      className="sp-button flat auto-width"
                      style={{
                        borderCollapse: 'collapse',
                        fontSize: 14,
                        lineHeight: '1.5',
                        borderColor: '#ddd',
                        borderWidth: 1,
                        borderStyle: 'solid',
                        border: 0,
                        width: 'auto !important',
                        borderRadius: 5,
                        boxShadow: 'none',
                        background: '#f0cd5b'
                      }}
                      width="auto !important"
                    >
                      <tbody>
                        <tr style={{ borderColor: 'transparent' }}>
                          <td
                            className="sp-button-text"
                            style={{
                              borderCollapse: 'collapse',
                              borderColor: 'transparent',
                              padding: 0,
                              borderWidth: 0,
                              borderStyle: 'none',
                              border: 0,
                              // align: 'left',
                              borderRadius: 5,
                              width: 'auto',
                              height: 40,
                              verticalAlign: 'middle',
                              textAlign: 'center'
                            }}
                            width="auto"
                            height={40}
                            valign="middle"
                            align="center"
                          >
                            <table
                              cellPadding={0}
                              border={0}
                              cellSpacing={0}
                              width="100%"
                              style={{
                                borderCollapse: 'collapse',
                                fontSize: 14,
                                lineHeight: '1.5',
                                border: 0
                              }}
                            >
                              <tbody>
                                <tr style={{ borderColor: 'transparent' }}>
                                  <td
                                    align="center"
                                    style={{
                                      borderCollapse: 'collapse',
                                      borderColor: 'transparent',
                                      padding: 0,
                                      border: 0,
                                      lineHeight: 1
                                    }}
                                  >
                                    <a
                                      style={{
                                        textDecoration: 'none',
                                        color: '#333',
                                        display: 'block',
                                        padding: '13.5px 18px',
                                        fontFamily:
                                          'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                        fontSize: 13,
                                        fontWeight: 'bold'
                                      }}
                                      href={ url }
                                    >
                                      { (date !== undefined)
                                        ? `Купить билет на ${date}${time !== '' ? ` в ${time}` : ''}`
                                        : 'Узнать подробнее и купить билет' }
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      style={{ fontSize: 14, lineHeight: '1.5', clear: 'both' }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
