import packageJson from '../../package.json'

export const appVersion = packageJson.version
export const domain = 'umnyeludi.ru'
export const scheduleSourceUrl = 'https://ulserver.vzltt.ru/schedule'
export const scheduleStatusSourceUrl = 'https://ulserver.vzltt.ru/status'
export const photosBaseUrl = 'https://ulserver.vzltt.ru/images'
export const photosIndex = 'index.txt'

export enum AppState {
  AutoMailing = 'autoMailing',
  AutoSchedule = 'autoSchedule',
  Input = 'input',
  InputError = 'inputError',
  Fetching = 'fetching',
  Processing = 'processing',
  Editing = 'editing',
}

export enum LsPrefixes {
  prefixPage = 'cachedPage__',
  keysPages = 'cachedPageKeys',
  prefixPreset = 'preset__',
  keysPresets = 'presetKeys',

  CACHEFILE_Header = 'ULCACHEDATA_',
  CACHEFILE_PagesArrayKey = 'cachedPagesData',
  CACHEFILE_PresetsArrayKey = 'presetsData',
}

export enum FileNames {
  scheduleNoUrls = 'schedule__noURLS.doc',
  scheduleUrls = 'schedule__URLS.doc',
  newsLetter = 'newsLetter.html',
  csvEvents = 'newsLetter.csv',
  cachedDataExport = 'UL_cachedData.json'
}
