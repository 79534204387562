import React, { useRef } from 'react'
import IconButton from '@mui/material/IconButton'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { MailPropsBase } from '../../../Pages/MailingPage'
import { UlEvent } from '../../../types/DTO/UlEvent.dto'
import { Chapter, ChapterObj, chapters } from '../../../params/mailing'

interface Props extends MailPropsBase {
  index?: number
  chapterId: Chapter
}

export default function ButtonAddItem (props: Props): JSX.Element {
  const { index, eventsDTO, setEventsDTO, chapterId } = props
  const newEventsDTO: UlEvent[] = [...eventsDTO]

  const ref = useRef<HTMLInputElement>(null)

  const handleAddItem = (): void => {
    const currentChapterParams = chapters.find(chapter => chapter.id === chapterId) as ChapterObj

    const newEvent: UlEvent = {
      imgUrl: '',
      url: '',
      timeStart: '',
      dateStart: '',
      dateEnd: '',
      formattedDate: '',
      title: '',
      city: '',
      price: '',
      length: '',
      eventType: currentChapterParams.availableEventTypes[0],
      lectorName: '',
      description: '',
      cachedDescription: '',
      fetchedDescription: '',
      isPremiere: false,
      chapterId,
      imgsPath: '',
      hasImagesIndex: false,

      isUnfetched: true
    }

    if (index !== undefined) {
      newEventsDTO.splice(index + 1, 0, newEvent)
    } else {
      newEventsDTO.unshift(newEvent)
      ref.current?.scrollIntoView({
        behavior: 'smooth'
      })
    }
    setEventsDTO(newEventsDTO)
  }

  return (<div
  ref={ref}
  style={{ display: 'flex', alignItems: 'center', alignContent: 'center', marginBottom: '1rem' }}
  >
    <IconButton aria-label="addItem" color='success' size='small' style={{ flex: 0, paddingLeft: '1rem' }} onClick={handleAddItem}>
    <AddCircleOutlineIcon />
  </IconButton></div>
  )
}
