
import { TemplateProps } from '../Components/Modals/ModalImageGenerator'
import VERT_TD_1 from '../fileTemplates/images/vertical/VERT_TD_1'
import VERT_TD_1_THUMB from '../assets/template_thumbs/vertical/vert_td_1.png'

import VERT_1_TD_1 from '../fileTemplates/images/vertical/VERT_1_TD_1'
import VERT_1_TD_1_THUMB from '../assets/template_thumbs/vertical/vert_1_td_1.png'

import VERT_2_1_TD from '../fileTemplates/images/vertical/VERT_2_1_TD'
import VERT_2_1_TD_THUMB from '../assets/template_thumbs/vertical/vert_2_1_td.png'

import VERT_2_TD_1 from '../fileTemplates/images/vertical/VERT_2_TD_1'
import VERT_2_TD_1_THUMB from '../assets/template_thumbs/vertical/vert_2_td_1.png'

import VERT_TD_2_1 from '../fileTemplates/images/vertical/VERT_TD_2_1'
import VERT_TD_2_1_THUMB from '../assets/template_thumbs/vertical/vert_td_2_1.png'

import VERT_2_TD_2 from '../fileTemplates/images/vertical/VERT_2_TD_2'
import VERT_2_TD_2_THUMB from '../assets/template_thumbs/vertical/vert_2_td_2.png'

import VERT_1_TD_2 from '../fileTemplates/images/vertical/VERT_1_TD_2'
import VERT_1_TD_2_THUMB from '../assets/template_thumbs/vertical/vert_1_td_2.png'

import VERT_TD_1_2 from '../fileTemplates/images/vertical/VERT_TD_1_2'
import VERT_TD_1_2_THUMB from '../assets/template_thumbs/vertical/vert_td_1_2.png'

import SQ_1 from '../fileTemplates/images/square/SQ_1'
import SQ_1_THUMB from '../assets/template_thumbs/square/sq_1.png'

import SQ_2 from '../fileTemplates/images/square/SQ_2'
import SQ_2_THUMB from '../assets/template_thumbs/square/sq_2.png'

import SQ_3 from '../fileTemplates/images/square/SQ_3'
import SQ_3_THUMB from '../assets/template_thumbs/square/sq_3.png'

import SQ_4 from '../fileTemplates/images/square/SQ_4'
import SQ_4_THUMB from '../assets/template_thumbs/square/sq_4.png'

import SQ_4_1 from '../fileTemplates/images/square/SQ_4_1'
import SQ_4_1_THUMB from '../assets/template_thumbs/square/sq_4_1.png'

import SQ_5_1 from '../fileTemplates/images/square/SQ_5_1'
import SQ_5_1_THUMB from '../assets/template_thumbs/square/sq_5_1.png'

import SQ_5 from '../fileTemplates/images/square/SQ_5'
import SQ_5_THUMB from '../assets/template_thumbs/square/sq_5.png'
import { CSSProperties } from 'react'

export interface TemplateItem {
  thumb: string
  template: (props: TemplateProps) => JSX.Element
  type: 'vertical' | 'square'
  style: any
}

type TemplateItems = Record<string, TemplateItem>

const vertStyle = { width: 1080, height: 1920, fontFamily: 'Furius', fontVariantLigatures: 'none' }
const sqStyle = { width: 1080, height: 1080, fontFamily: 'Furius', textTransform: 'uppercase', fontVariantLigatures: 'none' }

export const mapKeyToTemplate: TemplateItems = {
  VERT_TD_1: { thumb: VERT_TD_1_THUMB, template: VERT_TD_1, style: vertStyle, type: 'vertical' },
  VERT_1_TD_1: { thumb: VERT_1_TD_1_THUMB, template: VERT_1_TD_1, style: vertStyle, type: 'vertical' },
  VERT_2_1_TD: { thumb: VERT_2_1_TD_THUMB, template: VERT_2_1_TD, style: vertStyle, type: 'vertical' },
  VERT_2_TD_1: { thumb: VERT_2_TD_1_THUMB, template: VERT_2_TD_1, style: vertStyle, type: 'vertical' },
  VERT_TD_2_1: { thumb: VERT_TD_2_1_THUMB, template: VERT_TD_2_1, style: vertStyle, type: 'vertical' },
  VERT_2_TD_2: { thumb: VERT_2_TD_2_THUMB, template: VERT_2_TD_2, style: vertStyle, type: 'vertical' },
  VERT_1_TD_2: { thumb: VERT_1_TD_2_THUMB, template: VERT_1_TD_2, style: vertStyle, type: 'vertical' },
  VERT_TD_1_2: { thumb: VERT_TD_1_2_THUMB, template: VERT_TD_1_2, style: vertStyle, type: 'vertical' },
  SQ_1: { thumb: SQ_1_THUMB, template: SQ_1, style: sqStyle, type: 'square' },
  SQ_2: { thumb: SQ_2_THUMB, template: SQ_2, style: sqStyle, type: 'square' },
  SQ_3: { thumb: SQ_3_THUMB, template: SQ_3, style: sqStyle, type: 'square' },
  SQ_4: { thumb: SQ_4_THUMB, template: SQ_4, style: sqStyle, type: 'square' },
  SQ_4_1: { thumb: SQ_4_1_THUMB, template: SQ_4_1, style: sqStyle, type: 'square' },
  SQ_5_1: { thumb: SQ_5_1_THUMB, template: SQ_5_1, style: sqStyle, type: 'square' },
  SQ_5: { thumb: SQ_5_THUMB, template: SQ_5, style: sqStyle, type: 'square' }
}
