import React, { useRef, useEffect, useState, Suspense } from 'react'
import Modal from '@mui/material/Modal'
import * as htmlToImage from 'html-to-image'
import Box from '@mui/material/Box'
import { ModalBox, ModalContainer } from './style/ModalsStyle'
import { getKeyFromUrl } from '../../utils/URL/urlUtils'
import { photosBaseUrl, photosIndex } from '../../params/constants'
import axios from 'axios'
import parseIndex from '../../utils/Images/parseIndex'
import { TemplateItem, mapKeyToTemplate } from '../../params/imageTemplates'
import './style/ModalImageGenerator.css'
import { Button } from '@mui/material'

export interface BaseImageTemplateProps {
  eventType: string
  title: string
  date: string
}

export interface BaseImageTemplatePropsWithURL extends BaseImageTemplateProps {
  url: string
}

interface ModaImageGenProps extends BaseImageTemplatePropsWithURL {
  show: boolean
  setShow: (arg: boolean) => void
}

export interface TemplateProps extends BaseImageTemplateProps {
  photosArr: string[]
  photosUrl: string
  params: TemplateItem
}

const ModalImageGenerator = (props: ModaImageGenProps): JSX.Element => {
  const { show, setShow, url, date, title, eventType } = props

  const [error, setError] = useState<boolean>(true)
  const [photosArr, setPhotosArr] = useState<string[]>([])
  const [photosUrl, setPhotosUrl] = useState<string>('')
  const [urlKey, setUrlKey] = useState<string>('')
  const [activeTemplateName, setActiveTemplateName] =
    useState<string>('VERT_1_TD_1')

  useEffect(() => {
    const photosDirNameRaw = getKeyFromUrl(url)
    const photosDirName = photosDirNameRaw.endsWith('_') ? photosDirNameRaw.slice(0, -1) : photosDirNameRaw

    setUrlKey(photosDirName)

    const baseUrlWithDir = photosBaseUrl + `/${photosDirName}/`
    const indexUrl = baseUrlWithDir + photosIndex

    const getIndexFile = async (): Promise<void> => {
      const result = await axios.get(indexUrl)
      if (result.status === 200) {
        setPhotosUrl(baseUrlWithDir)
        setPhotosArr(parseIndex(result.data))
        setError(false)
      }
    }

    getIndexFile().catch(() => { setError(true) })
  }, [])

  const domEl = useRef(null)
  const previewEl = useRef<HTMLDivElement>(null)

  const downloadImage = async (): Promise<void> => {
    const dataUrl =
      domEl.current !== null
        ? await htmlToImage.toJpeg(domEl.current, { cacheBust: true })
        : ''

    // download image
    const link = document.createElement('a')
    link.download = `${urlKey}_${activeTemplateName}.jpg`
    link.href = dataUrl
    link.click()
  }

  const handleChangeTemplate = (newKey: string): void => {
    if (activeTemplateName === newKey) { return }
    setActiveTemplateName(newKey)
    if (previewEl.current !== null) {
      previewEl.current.style.opacity = '0'
      setTimeout(() => { if (previewEl.current !== null) { previewEl.current.style.opacity = '1' } }, 200)
    }
  }

  const CurrentTemplate = mapKeyToTemplate[activeTemplateName].template

  return error
    ? <></>
    : <Modal onClose={setShow} open={show} sx={ModalContainer}>
      <Box sx={{ ...ModalBox, height: 600 }}>

        <div className="templateList">
          <div>
          {Object.keys(mapKeyToTemplate).map((key, index) => (
            mapKeyToTemplate[key].type === 'vertical' &&
          <img
            style={{
              height: 32,
              marginRight: 5,
              boxShadow: activeTemplateName === key ? '0 0 0px 2px #4e55c4' : 'none'
            }}
              key={index}
              src={mapKeyToTemplate[key].thumb}
              onClick={() => { handleChangeTemplate(key) }}
            />
          ))}</div>

          <div>
          {Object.keys(mapKeyToTemplate).map((key, index) => (
            mapKeyToTemplate[key].type === 'square' &&
            <img
            style={{
              height: 32,
              marginRight: 5,
              boxShadow: activeTemplateName === key ? '0 0 0px 2px #4e55c4' : 'none'
            }}
              key={index}
              src={mapKeyToTemplate[key].thumb}
              onClick={() => { handleChangeTemplate(key) }}
            />
          ))}</div>

        </div>
        <div className="previewContainer">
          <div className="scaledPreview" ref={previewEl}>
            <div className="renderArea" style={{ width: mapKeyToTemplate[activeTemplateName].style.width, height: mapKeyToTemplate[activeTemplateName].style.height }} ref={domEl}>
            <Suspense fallback={<div style={{ color: '#000' }}>Loading...</div>}>
              <CurrentTemplate
                params={mapKeyToTemplate[activeTemplateName]}
                date={date}
                eventType={eventType}
                photosArr={photosArr}
                photosUrl={photosUrl}
                title={title}
              />
              </Suspense>
            </div>
          </div>
        </div>
        <Button variant='contained' size='small' sx={{ marginTop: 1 }} onClick={downloadImage}>Скачать JPG</Button>

      </Box>

    </Modal>
}

export default ModalImageGenerator
