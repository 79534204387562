export default (blob: Blob, fileName: string): void => {
  const cleanBlobUrl = (): void => {
    setTimeout(() => { URL.revokeObjectURL(a.href) }, 1 * 1000)
  }

  const a = document.createElement('a')
  a.download = fileName
  a.href = URL.createObjectURL(blob)
  a.addEventListener('click', cleanBlobUrl)
  a.click()
  a.removeEventListener('click', cleanBlobUrl)
}
