import React, { useState, useEffect } from 'react'
import selectIUniqueImages from '../../../utils/Images/selectIUniqueImages'
import { TemplateProps } from '../../../Components/Modals/ModalImageGenerator'
import '../style.css'
import TitleDate from './TitleDate'

const VERT_1_TD_2 = (props: TemplateProps): JSX.Element => {
  const { title, date, photosUrl, photosArr, params } = props

  const [selectedImages, setSelectedImages] = useState<string[]>([])

  useEffect(() => {
    const images = selectIUniqueImages(photosArr, 3)
    setSelectedImages(images)
  }, [photosArr])

  const photoBgrUrl0 = `url(${photosUrl}${selectedImages[0]})`
  const photoBgrUrl1 = `url(${photosUrl}${selectedImages[1]})`
  const photoBgrUrl2 = `url(${photosUrl}${selectedImages[2]})`

  return (
    <div className="templateMainContainer" style={params.style}>

      <div
        className="bgrImage"
        style={{
          backgroundImage: photoBgrUrl0,
          top: 0,
          left: 0,
          width: 1080,
          height: 785
        }}
      ></div>

      <TitleDate title={title} date={date} style={{ position: 'absolute', top: 785, left: 0, width: 1080, height: 340 }}/>
      <div
        className="bgrImage"
        style={{
          backgroundImage: photoBgrUrl1,
          top: 1135,
          left: 0,
          width: 533,
          height: 785
        }}
      ></div>
      <div
        className="bgrImage"
        style={{
          backgroundImage: photoBgrUrl2,
          top: 1135,
          left: 547,
          width: 533,
          height: 785
        }}
      ></div>
    </div>
  )
}

export default VERT_1_TD_2
