import React, { CSSProperties } from 'react'
import Title from '../../../Components/ImageGen/Title'
import Date from '../../../Components/ImageGen/Date'

interface Props { title: string, date: string, style: CSSProperties }

const TitleDate = (props: Props): JSX.Element => {
  const { title, date, style } = props

  return (
    <div style={{
      ...style,
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'center'
    }}>
        <Title
        title={title}
        style={{
          // border: '1px red solid',
          minHeight: '60%',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          textAlign: 'center',
          alignItems: 'center',
          lineHeight: 1.1,
          color: '#a20000'
        }}
      />
      <Date
        date={date}
        style={{
          // border: '1px blue solid',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'start',
          minHeight: '40%',
          textAlign: 'center',
          color: '#3b125e'
        }}
      />
    </div>
  )
}

export default TitleDate
