export default (photos: string[], num: number): string[] => {
  if (photos.length === 0) { return [] }
  const indexes: number[] = []

  while (indexes.length < num) {
    const randomNum = Math.floor(Math.random() * photos.length)
    if (!indexes.includes(randomNum)) { indexes.push(randomNum) }
  }

  return indexes.map((photoIndex) => photos[photoIndex])
}
