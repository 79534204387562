import React from 'react'
import Purchase from './Event/Purchase'
import Date from './Event/Date'
import Separator from './Event/Separator'
import { mailingEventTypes } from '../../../params/mailing'
import parse from 'html-react-parser'
import { AdditionalDate, UlEvent } from '../../../types/DTO/UlEvent.dto'

interface Props {
  event: UlEvent
}

export default function RenderEventHTML ({ event }: Props): JSX.Element {
  const moreDates = event.allDates

  const eventTypeName = mailingEventTypes[event.eventType].renderName ?? mailingEventTypes[event.eventType].name

  return (
    <>
    <table
      cellPadding={0}
      cellSpacing={0}
      style={{
        borderCollapse: 'collapse',
        fontSize: 14,
        lineHeight: '1.5',
        width: '100%'
      }}
      border={0}
      width='100%'
    >
      <tbody>
        <tr style={{ borderColor: 'transparent' }}>
          <th
            style={{
              borderColor: 'transparent',
              fontWeight: 400,
              textAlign: 'left',
              verticalAlign: 'top'
            }}
            className='tc'
            align='left'
          >
            <table
              border={0}
              width='100%'
              cellPadding={0}
              cellSpacing={0}
              style={{
                borderCollapse: 'collapse',
                fontSize: 14,
                lineHeight: '1.5',
                backgroundColor: '#fff',
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0
              }}
            >
              <tbody>
                <tr style={{ borderColor: 'transparent' }}>
                  <td
                    style={{
                      borderCollapse: 'collapse',
                      borderColor: 'transparent'
                    }}
                  >
                    <table
                      width='100%'
                      cellPadding={0}
                      cellSpacing={0}
                      id='wout_block_out_block_114'
                      style={{
                        borderCollapse: 'separate',
                        fontSize: 14,
                        lineHeight: '1.5',
                        fontFamily:
                          'Arial, "Helvetica Neue", Helvetica, sans-serif',
                        fontWeight: 'normal',
                        margin: 0,
                        overflow: 'hidden'
                      }}
                    >
                      <tbody>
                        <tr
                          className='content-row'
                          style={{
                            borderColor: 'transparent',
                            color: '#444',
                            fontFamily:
                              'Arial, "Helvetica Neue", Helvetica, sans-serif'
                          }}
                        >
                          <td
                            className='content-cell padding-bottom-0'
                            width={270}
                            style={{
                              borderCollapse: 'collapse',
                              borderColor: 'transparent',
                              verticalAlign: 'top',
                              paddingLeft: 15,
                              paddingRight: 15,
                              paddingTop: 15,
                              paddingBottom: 0
                            }}
                            valign='top'
                          >

{ moreDates !== undefined
  ? moreDates.map((item: AdditionalDate, index: number) => <Date key={index} date={item.formattedDate} time={item.timeStart}/>)
  : <Date date={event.formattedDate} time={event.timeStart}/>
                                            }

{event.isPremiere
  ? <h3
                              style={{
                                lineHeight: '1.5',
                                margin: '0 0 10px',
                                fontWeight: 'normal',
                                fontSize: 24,
                                color: '#444',
                                fontFamily:
                                  'Arial, "Helvetica Neue", Helvetica, sans-serif'
                              }}
                            >
                              <span style={{ fontSize: 18, color: '#ff6600' }}>
                                <strong>ПРЕМЬЕРА</strong>
                              </span>
                            </h3>
  : null}

                            <h3
                              style={{
                                lineHeight: '1.5',
                                margin: '0 0 10px',
                                fontWeight: 'normal',
                                fontSize: 24,
                                color: '#444',
                                fontFamily:
                                  'Arial, "Helvetica Neue", Helvetica, sans-serif'
                              }}
                            >
                              <strong
                                style={{
                                  fontSize: 18,
                                  color: 'inherit',
                                  fontFamily: 'inherit'
                                }}
                              >
                               {event.title}

                              </strong>
                            </h3>
                            <h3
                              style={{
                                lineHeight: '1.5',
                                margin: '0 0 10px',
                                fontWeight: 'normal',
                                fontSize: 24,
                                color: '#444',
                                fontFamily:
                                  'Arial, "Helvetica Neue", Helvetica, sans-serif'
                              }}
                            >
                              <span style={{ fontSize: 18, color: '#ff6600' }}>
                                <strong>{eventTypeName}</strong>
                              </span>
                            </h3>
                            <p
                              style={{
                                lineHeight: '1.5',
                                margin: '0 0 10px',
                                fontSize: 'inherit',
                                color: 'inherit',
                                fontFamily:
                                  'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                fontWeight: 'normal',
                                padding: 0
                              }}
                            >
                             {parse(event.description)}
                            </p>
                            <p
                              style={{
                                lineHeight: '1.5',
                                margin: '0 0 10px',
                                fontSize: 'inherit',
                                color: 'inherit',
                                fontFamily:
                                  'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                fontWeight: 'normal',
                                padding: 0
                              }}
                            >
                              <strong>Стоимость: {event.price} р.</strong>
                            </p>
                            <div
                              style={{
                                fontSize: 14,
                                lineHeight: '1.5',
                                clear: 'both'
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              border={0}
              width='100%'
              cellPadding={0}
              cellSpacing={0}
              style={{
                borderCollapse: 'collapse',
                fontSize: 14,
                lineHeight: '1.5',
                backgroundColor: '#fff',
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0
              }}
            >
              <tbody>
                <tr style={{ borderColor: 'transparent' }}>
                  <td
                    style={{
                      borderCollapse: 'collapse',
                      borderColor: 'transparent'
                    }}
                  >
                    <table
                      width='100%'
                      cellPadding={0}
                      cellSpacing={0}
                      style={{
                        borderCollapse: 'separate',
                        fontSize: 14,
                        lineHeight: '1.5',
                        overflow: 'hidden'
                      }}
                    >
                      <tbody>
                        <tr
                          className='content-row'
                          style={{
                            borderColor: 'transparent',
                            color: '#444',
                            fontFamily:
                              'Arial, "Helvetica Neue", Helvetica, sans-serif'
                          }}
                        >
                          <td
                            className='content-cell'
                            width={270}
                            style={{
                              borderCollapse: 'collapse',
                              borderColor: 'transparent',
                              verticalAlign: 'top',
                              paddingLeft: 15,
                              paddingRight: 15,
                              paddingTop: 5,
                              paddingBottom: 15
                            }}
                            valign='top'
                          >
                            <table
                              cellPadding={0}
                              border={0}
                              cellSpacing={0}
                              align='left'
                              className='sp-button flat auto-width'
                              style={{
                                borderCollapse: 'collapse',
                                fontSize: 14,
                                lineHeight: '1.5',
                                borderColor: '#ddd',
                                borderWidth: 1,
                                borderStyle: 'solid',
                                border: 0,
                                width: 'auto !important',
                                borderRadius: 5,
                                boxShadow: 'none',
                                background: '#f0cd5b'
                              }}
                              width='auto !important'
                            >
                              <tbody>
                                <tr style={{ borderColor: 'transparent' }}>
                                  <td
                                    className='sp-button-text'
                                    style={{
                                      borderCollapse: 'collapse',
                                      borderColor: 'transparent',
                                      padding: 0,
                                      borderWidth: 0,
                                      borderStyle: 'none',
                                      border: 0,
                                      borderRadius: 5,
                                      width: 'auto',
                                      height: 40,
                                      verticalAlign: 'middle',
                                      textAlign: 'center'
                                    }}
                                    width='auto'
                                    height={40}
                                    valign='middle'
                                    align='center'
                                  >

                                            { moreDates !== undefined
                                              ? moreDates.map((item: AdditionalDate, index: number) => <Purchase key={index} date={item.formattedDate} time={item.timeStart} url={item.url}/>)
                                              : <Purchase url={event.url}/>
                                            }

                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div
                              style={{
                                fontSize: 14,
                                lineHeight: '1.5',
                                clear: 'both'
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </th>
          <th
            style={{
              borderColor: 'transparent',
              fontWeight: 400,
              textAlign: 'left',
              verticalAlign: 'top'
            }}
            className='tc'
            align='left'
          >
            <table
              border={0}
              width='100%'
              cellPadding={0}
              cellSpacing={0}
              style={{
                borderCollapse: 'collapse',
                fontSize: 14,
                lineHeight: '1.5',
                backgroundColor: '#fff',
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0
              }}
            >
              <tbody>
                <tr style={{ borderColor: 'transparent' }}>
                  <td
                    style={{
                      borderCollapse: 'collapse',
                      borderColor: 'transparent'
                    }}
                  >
                    <table
                      width='100%'
                      cellPadding={0}
                      cellSpacing={0}
                      id='wout_block_116_element_0'
                      style={{
                        borderCollapse: 'separate',
                        fontSize: 14,
                        lineHeight: '1.5',
                        overflow: 'hidden'
                      }}
                    >
                      <tbody>
                        <tr
                          className='content-row'
                          style={{
                            borderColor: 'transparent',
                            color: '#444',
                            fontFamily:
                              'Arial, "Helvetica Neue", Helvetica, sans-serif'
                          }}
                        >
                          <td
                            className='content-cell'
                            width={270}
                            style={{
                              borderCollapse: 'collapse',
                              borderColor: 'transparent',
                              verticalAlign: 'top',
                              paddingLeft: 15,
                              paddingRight: 15,
                              paddingTop: 15,
                              paddingBottom: 15
                            }}
                            valign='top'
                          >
                            <div
                              id='wout_block_116_element_0'
                              style={{
                                fontSize: 14,
                                lineHeight: '1.5',
                                width: '100%',
                                height: 253,
                                display: 'block'
                              }}
                            >
                              <a
                                className='link_img'
                                href={event.url}
                                style={{
                                  textDecoration: 'none',
                                  color: '#0089bf',
                                  display: 'block'
                                }}
                              >
                                <img
                                  width={270}
                                  height='auto'
                                  className='desktop  sp-img small_img '
                                  alt={event.title}
                                  src={event.imgUrl}
                                  style={{
                                    height: 'auto',
                                    lineHeight: '100%',
                                    outline: 0,
                                    textDecoration: 'none',
                                    border: 0,
                                    margin: 0,
                                    display: 'block'
                                  }}
                                />
                                {/* [if !mso]><! */}
                                <div
                                  style={{
                                    fontSize: 14,
                                    lineHeight: '1.5'
                                  }}
                                >
                                  <img
                                    width='100%'
                                    height='auto'
                                    className='mobile  sp-img small_img '
                                    alt={event.title}
                                    src={event.imgUrl}
                                    style={{
                                      height: 'auto',
                                      lineHeight: '100%',
                                      outline: 0,
                                      textDecoration: 'none',
                                      border: 0,
                                      display: 'none',
                                      width: '100%',
                                      maxWidth: '100% !important'
                                    }}
                                  />
                                </div>
                                {/* <![endif] */}
                              </a>
                            </div>
                            <div
                              style={{
                                fontSize: 14,
                                lineHeight: '1.5',
                                clear: 'both'
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </th>
        </tr>
      </tbody>
    </table>
    <Separator/>
    </>
  )
}
