import React from 'react'
import parse from 'html-react-parser'
import { chapterIntroByIntroId } from '../../../../params/mailing'

interface Props {
  introId: string
}

const ChapterIntro = (props: Props): JSX.Element => {
  const { introId } = props

  return (
    <table
      cellPadding={0}
      cellSpacing={0}
      style={{
        borderCollapse: 'collapse',
        fontSize: 14,
        lineHeight: '1.5',
        width: '100%'
      }}
      border={0}
      width="100%"
    >
      <tbody>
        <tr style={{ borderColor: 'transparent' }}>
          <td
            style={{
              borderCollapse: 'collapse',
              borderColor: 'transparent',
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
              paddingBottom: 0,
              verticalAlign: 'top'
            }}

            valign="top"
          >
            <table
              cellPadding={0}
              cellSpacing={0}
              style={{
                borderCollapse: 'collapse',
                fontSize: 14,
                lineHeight: '1.5',
                width: '100%'
              }}
              border={0}
              width="100%"
            >
              <tbody>
                <tr style={{ borderColor: 'transparent' }}>
                  <th
                    style={{
                      borderColor: 'transparent',
                      fontWeight: 400,
                      textAlign: 'left',
                      verticalAlign: 'top'
                    }}

                    align="left"
                  >
                    <table
                      border={0}
                      width="100%"
                      cellPadding={0}
                      cellSpacing={0}
                      style={{
                        borderCollapse: 'collapse',
                        fontSize: 14,
                        lineHeight: '1.5',
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0
                      }}
                    >
                      <tbody>
                        <tr style={{ borderColor: 'transparent' }}>
                          <td
                            style={{
                              borderCollapse: 'collapse',
                              borderColor: 'transparent',
                              verticalAlign: 'top'
                            }}
                            valign="top"
                          >
                            <table
                              width="100%"
                              cellPadding={0}
                              cellSpacing={0}
                              id="m_-428655247244014550m_-2500504993946967269wout_block_out_block_222"
                              style={{
                                borderCollapse: 'separate',
                                fontSize: 14,
                                lineHeight: '1.5',
                                fontWeight: 'normal',
                                margin: 0,
                                overflow: 'hidden'
                              }}
                            >
                              <tbody>
                                <tr
                                  style={{
                                    borderColor: 'transparent',
                                    color: '#444',
                                    fontFamily:
                                      'Arial,"Helvetica Neue",Helvetica,sans-serif'
                                  }}
                                >
                                  <td
                                    width={570}
                                    style={{
                                      borderCollapse: 'collapse',
                                      borderColor: 'transparent',
                                      verticalAlign: 'top',
                                      paddingLeft: 15,
                                      paddingRight: 15,
                                      paddingTop: 15,
                                      paddingBottom: 15
                                    }}
                                    valign="top"
                                  >
                                    <p
                                      style={{
                                        lineHeight: 'inherit',
                                        margin: '0 0 10px',
                                        fontSize: 'inherit',
                                        color: 'inherit',
                                        fontFamily:
                                          'Arial,"Helvetica Neue",Helvetica,sans-serif',
                                        fontWeight: 'normal',
                                        padding: 0
                                      }}
                                    >
                                {parse(chapterIntroByIntroId[introId])}
                                    </p>
                                    <div
                                      style={{
                                        fontSize: 14,
                                        lineHeight: '1.5',
                                        clear: 'both'
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      border={0}
                      width="100%"
                      cellPadding={0}
                      cellSpacing={0}
                      style={{
                        borderCollapse: 'collapse',
                        fontSize: 14,
                        lineHeight: '1.5',
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0
                      }}
                    >
                      <tbody>
                        <tr style={{ borderColor: 'transparent' }}>
                          <td
                            style={{
                              borderCollapse: 'collapse',
                              borderColor: 'transparent',
                              verticalAlign: 'top'
                            }}
                            valign="top"
                          >
                            <div
                              style={{
                                fontSize: 14,
                                lineHeight: '1.5',
                                paddingLeft: 15,
                                paddingRight: 15,
                                paddingTop: 15,
                                paddingBottom: 15
                              }}
                            >
                              <hr
                                id="m_-428655247244014550m_-2500504993946967269iout_block_190_element_0"
                                style={{
                                  margin: 0,
                                  borderTopStyle: 'solid',
                                  borderTopWidth: 1,
                                  borderTopColor: '#b2b2b2',
                                  borderBottom: 0,
                                  borderLeft: 0,
                                  borderRight: 0
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </th>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default ChapterIntro
