import React, { CSSProperties } from 'react'
import useFitText from 'use-fit-text'

interface Props { title: string, style: CSSProperties }

const Title = (props: Props): JSX.Element => {
  const { title, style } = props
  const { fontSize, ref } = useFitText({ maxFontSize: 400, minFontSize: 10, resolution: 1 })

  const formattedTitle = title
    .replaceAll(/(?<=&nbsp;| )([а-яА-Яa-zA-Z]{1,2}) (?=[а-яА-Яa-zA-Z])/g, '$1\xa0') // висячие предлоги
    .replaceAll('-', '-\u2060') // висячие тире/дефисы
    .replaceAll(/(\s?\p{L}\.)(\s)/gu, '$1' + '\u00a0') // убираем перенос фамилии на новую строку в инициалах с именами: А. Б. Петров, А. Иванов и тд.
    .replaceAll(' - ', ' — ') // минусы на длинные тире

    // 3 следующих регекса в итоге меняют все "" на «»
    .replace(/(^|[\s;([-])"/g, '$1«')
    .replace(/"([\s-.!,:;?)\]\n\r]|$)/g, '»$1')
    .replace(/([^\s])"([^\s])/g, '$1»$2')

  return <div
    ref={ref}
    className="title"
    style={{
      fontSize,
      ...style
    }}>{formattedTitle}</div>
}

export default Title
